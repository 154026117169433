import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PilotConstants } from '../commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrewAssignmentRequest } from '../domain/crew-assignment-request';
import * as moment from 'moment';
import { FlightId } from '../domain/flight-id.model';
import { SigaInformation } from '../domain/roster/siga-information.model';
import { CateringByFlight } from '../domain/roster/catering-by-flight.model';
import { Activity } from '../domain/roster/activity.model';
import { GateBridgeRequest } from '../domain/GateBridgeRequest';
import { GateBridgeResponse } from '../domain/GateBridgeResponse';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
    private httpClient: HttpClient, 
    private Constants: PilotConstants
  ) { }

  getRoster(operator: string, date): Observable<Array<Activity>> {
    let params = new HttpParams()
      .set('operator', operator)
      .set('startDate', moment(date.startDate, "YYYY-MM-DD").utc().format('DD-MM-YYYY') + ' 00:00:00')
      .set('endDate', moment(date.endDate, "YYYY-MM-DD").utc().format('DD-MM-YYYY') + ' 23:59:59');
      
    const URL = `${environment.apiUrl}${this.Constants.APPLICATION_ROLE_PATH}${this.Constants.REST_V2}${this.Constants.ROSTER_BASE}`;
    return this.httpClient.get<Array<Activity>>(URL, { params })
      .pipe( map(res => res.map(f => Object.assign(new Activity(), f))));
  }

  getCrewRosterAssignment(crewAssignmentRequest: CrewAssignmentRequest): Observable<any> {    
    let params = new HttpParams()
      .set('flightNumber', crewAssignmentRequest?.flightNumber)
      .set('departureDate', crewAssignmentRequest?.departureDate)
      .set('carrier', crewAssignmentRequest?.carrier)
      .set('departure', crewAssignmentRequest?.departure)
      .set('arrival', crewAssignmentRequest?.arrival);
    const URL = `${environment.apiUrl}${this.Constants.APPLICATION_ROLE_PATH}${this.Constants.REST_V1}${this.Constants.ROSTER_BASE_PATH}${this.Constants.ROSTER_CREW_ASSIGNMENT}`;
    return this.httpClient.get(URL, { params });
  }

  getSabreGateAndBridge(flight : GateBridgeRequest): Observable<GateBridgeResponse>{
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.ROSTER_BASE_PATH}${this.Constants.ROSTER_SABRE_GATE_AND_BRIDGE}`;
    return this.httpClient.post<GateBridgeResponse>(URL, flight);
  }

  getNextFlight(roster: Array<Activity>): { index: number, flight: Activity } {
    let index = null;
    let flight =roster.find((activity: Activity, i: number) => {
      let difference: number;
      if (activity.flightLeg != null && activity.flightLeg.operator != null) {
        let dateFlight = moment(activity.flightLeg.departure.dates.local, "YYYY-MM-DD HH:mm:ss")
                          .add(activity.flightLeg.minutesOfFlight, 'minutes');

        const utcDate = moment(activity.flightLeg.arrival.dates.utc, "YYYY-MM-DD HH:mm:ss");
        const localDate = moment(activity.flightLeg.arrival.dates.local, "YYYY-MM-DD HH:mm:ss");

        //Current time based on difference between utc and local timezone
        const dateNow = moment().utc().add(moment.duration(localDate.diff(utcDate)).asMinutes(), 'minutes').format("YYYY-MM-DD HH:mm:ss");
        difference = dateFlight.diff(dateNow);
      }
      index = i;
      return activity.code == "LEG" && difference >= 0 && !activity.isBlocked;
    });

    return { index, flight };
  }

  getGate(activitie: Activity) {
    return new Promise<string>((resolve, reject) => {
      let request = new GateBridgeRequest();
      request.departureAirport = activitie.flightLeg.departure.iataCode;
      request.flightNumber = activitie.flightLeg.flightNumber.toString();
      let response: string;
      this.getSabreGateAndBridge(request)
        .pipe(finalize(() => resolve(response)))
        .subscribe({
          next: resp => {
            response = resp.gate;
          },
          error: err => {
            response = "-";
            console.log(err);
          }
        });
    });
  }
}
