import { Component, ViewChild, EventEmitter, Output, NgZone, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { SecurityService } from './core/services/security/security.service';
import { interval, Subscription } from 'rxjs';
import { CommonsService } from './core/services/commons/commons.service';
import { ProfileModalComponent } from './modules/pilot/components/modals/profile-modal/profile-modal.component';
import { NetworkService } from './modules/pilot/services/network/network.service';
import { UserService } from './core/services/user/user.service';
import { IUser } from './modules/pilot/domain/user/user';
import { RemoteConfigService } from './modules/pilot/services/remote-config/remote-config.service';
import { Device } from '@capacitor/device';
import { PreferencesService } from './core/services/preferences/preferences.service';
import { DeviceService } from './modules/pilot/services/device/device.service';

import { register } from 'swiper/element/bundle';
import { PilotConstants } from './modules/pilot/commons/pilot-constants';
import { UiService } from './modules/pilot/services/ui/ui.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from 'src/environments/environment';
import { PushNotificationsService } from './modules/pilot/services/push-notifications/push-notifications.service';
import { App } from '@capacitor/app';

// register Swiper custom elements
register();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {

  sidenavtype: MatDrawerMode = 'over';
  isConnected: boolean;
  nav: boolean = false;
  private sidenav: MatSidenav;
  public route: Promise<any>;
  showLoading: boolean;
  statusSendReport: boolean;
  innerWidth: any;
  innerHeight: any;
  componentShow = '';
  user: IUser;
  userSubs: Subscription;
  refreshSubs: Subscription;
  currentURL: string = '';
  hideSection: boolean = this.constants.hideSectionsStores;
  validateVersionApp = false;
  eventRefresh: any;
  disabledRefreshScreen = this.constants.DISABLED_REFRESH_SCREENS;
  disableRefresh: boolean = false;
  showSpinner: boolean = false;
  @Output() toggleSidenav: EventEmitter<any> = new EventEmitter();
  fireRefreshToken: boolean = true;

  @ViewChild('sidenav', { static: false }) set content(content: MatSidenav) {
    this.sidenav = content;
  }

  constructor(
    public _user: UserService,
    private router: Router,
    private securityService: SecurityService,
    private ngZone: NgZone,
    private commonsService: CommonsService,
    public dialog: MatDialog,
    private _network: NetworkService,
    private _preferences: PreferencesService,
    public constants: PilotConstants,
    private remoteConfig: RemoteConfigService,
    private _device: DeviceService,
    private _ui: UiService,
    private _pushNotifications: PushNotificationsService,
  ) {

    this.initializeApp();

    this.refreshSubs = this._ui.getStateRefresh()
      .subscribe(state => {
        if (state.finishEvent) {
          this.eventRefresh?.target?.complete();
          setTimeout(() => { this.showSpinner = false; }, 1000);
        }
      });

    this.securityService.showLoading.subscribe(
      value => {
        this.ngZone.run(() => this.showLoading = value)
      }
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        this.disableRefresh = this.currentURL.includes("/reports") ? true : this.disabledRefreshScreen.includes(this.currentURL);
        if (this.sidenav != undefined && this.sidenav.opened) this.closeSidebar(true);
        this.nav = !(this.currentURL == "/login" || this.currentURL == "/on-boarding") ? true : false;
        this.valideUrl(event.url);
        (<any>window).gtag('config', 'UA-124922434-1', { 'page_path': event.urlAfterRedirects });
      }
    });
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  ngOnInit() {

    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(state => this.isConnected = state);
    }
    valideNetwork();

    this._ui.setStatusBar();
  }

  async initializeApp() {
    let info = await Device.getInfo();

    let isAppIpads: boolean = false;
    if (info.platform != 'web') {
      let app = await App.getInfo();
      isAppIpads = app.id == this.constants.IDS_IOS.ID_APP_STORE_ENTERPRISE;
      GoogleAuth.initialize({
        grantOfflineAccess: true,
        scopes: ['profile', 'email'],
        clientId: info.platform == "ios" ? environment.iosClientId : environment.androidClientId,
      });
    }
    this.userSubs = this._user.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        // this.listenAppStateChange();
        // Verificar si la version instalada esta desactualizada en iOS y Android una unica vez por ingreso
        this.handleVersionApp();
        this.initPush();
        !isAppIpads && this.refreshToken();
      }
    });

    this.handleOperatorAndFleetConfig();
  }

  handleOperatorAndFleetConfig() {
    // Set operator and fleet is mandatory
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd && !value.url.includes('login') && !value.url.includes('on-boarding')) {
        if ((!this.user?.profile || !this.user?.profile?.operator) && this.isConnected) {
          const dialogRef = this.dialog.open(ProfileModalComponent, {
            width: "500px",
            data: { message: "Please, set an operator and fleet" },
            disableClose: true
          });

          dialogRef.afterClosed().subscribe(result => {
            const path = window.location.pathname;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate([path]));
          });
        }
      }
    });
  }

  handleVersionApp() {
    if (this.user?.roles?.length > 0 && this.user?.profile && this.user?.profile?.onBoarding && !this.validateVersionApp) {
      this.currentURL != "/on-boarding" && this.getVersionApp();
    }
  }

  initPush() {
    if (this.user?.idToken) {
      this._pushNotifications.initPush();
    }
  }

  refreshToken() {
    if (this.user?.idToken && this.fireRefreshToken) {
      this.fireRefreshToken = false;
      this.initializeTokenRefresh();
    }
  }

  async initializeTokenRefresh() {
    console.warn("initializeTokenRefresh");
    let refresh = await this.getRemoteConfigRefreshToken();
    if (refresh) {
      this.securityService.refreshToken();
      this.ngZone.runOutsideAngular(() => {
        // Refresh token every 20 minutes
        interval(1200000).subscribe(() => {
          console.warn("refresh token");
          this.securityService.refreshToken();
        });
      });
    }
  }

  getRemoteConfigRefreshToken(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.remoteConfig.getParameterRemoteConfig('refresh_token').subscribe({
        next: (value: boolean) => {
          resolve(value);
        },
        error: (err) => {
          console.log("Error getRemoteConfigRefreshToken: ", err);
          reject(false);
        },
      });
    });
  }

  valideUrl(url: string) {
    if (url === '/') {
      this.componentShow = 'home';
    } else if (url.includes('fuel')) {
      this.componentShow = 'fuel';
      const x = document.getElementsByClassName('mat-drawer-content');
      x[0].scrollTop = 0;
    } else if (url.includes('flightInformation')) {
      this.componentShow = 'roster';
    } else if (url.includes('briefing')) {
      this.componentShow = 'briefing';
    } else if (url.includes('section=all') || url.includes('section=flights') || url.includes('section=activities')) {
      this.componentShow = 'roster';
    } else {
      this.componentShow = url.replace('/', '');
    }
  }

  showComponent(component: string) {
    this.componentShow = component;
    let event: string = "";
    switch (this.componentShow) {
      case 'safety':
        event = "Safety";
        break;
      case 'fuel':
        event = "Efficiency";
        break;
      case 'home':
        event = "Home";
        break;
    }
    this.commonsService.registryEvent({ category: 'menuButtonBar', event});
  }

  openSidebar(event) {
    this.sidenav.open();
  }

  closeSidebar(event) {
    this.sidenav.close();
  }

  async getVersionApp() {
    let info = await Device.getInfo();
    let { platform } = info;
    let version = await this._preferences.getItem('version');
    this.remoteConfig.getParameterRemoteConfig('version').subscribe(
      resp => {
        if (!version) this._preferences.setItem('version', resp);
        if (platform != "web") {
          this._device.validateVersionDevice(resp[platform], platform);
          this.validateVersionApp = true;
        }
      }
    );
  }

  handleRefresh(event: any) {
    this.eventRefresh = event;
    if (this.isConnected) {
      this.showSpinner = true;
      this._ui.setStateRefresh({ refresh: true, finishEvent: false });
    } else {
      this.eventRefresh?.target?.complete();
    }
  }

}
