import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import jsPDF, { GState } from 'jspdf';
import 'jspdf-autotable';
import { LimopsReportService } from 'src/app/modules/pilot/services/limops-report.service';
import { LimopsRequest } from 'src/app/modules/pilot/domain/dispatch/limops-request';
import { LimopsResponse } from 'src/app/modules/pilot/domain/dispatch/limops-response';
import { LimopsReportDetailComponent } from './limops-report-detail/limops-report-detail.component';
import { SubcomponentState } from 'src/app/modules/pilot/domain/dispatch/sub-component-state.model';


@Component({
  selector: 'limops-report',
  templateUrl: './limops-report.component.html',
  styleUrls: ['./limops-report.component.css', '../dispatch.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ transform: 'translateX(100%)' }),
          style({ transform: 'translateX(0%)' }),
        ])),
        transition(':leave', [
          animate('0.5s', keyframes([
            style({ transform: 'translateX(0%)' }),
            style({ transform: 'translateX(100%)' })
          ]))
        ])
      ])
    ])
  ],
  standalone: false
})
export class LimopsReportComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(LimopsReportDetailComponent) limopsReportDetailComponent: LimopsReportDetailComponent;

  public dialogRef: any;


  @Input() aircraftRegistration: string = "";
  @Input() aircraftSubtype: string = "";

  public displayedColumnsOpLim: string[] = ['barCode', 'mocNo', 'ACSubtype', 'ACRegistration', 'mel', 'procedure', 'level', 'defectFound', 'openDate', 'equipment', 'etops', 'cat', 'rvsm', 'rnav', 'rnp', 'cargoAffect'];
  public displayedColumnsMainPro: string[] = ['barCode', 'mocNo', 'ACSubtype', 'ACRegistration', 'mel', 'level', 'defectFound', 'openDate', 'actionRequired', 'repetetiveInspection'];
  public displayedColumnsPassCon: string[] = ['barCode', 'mocNo', 'ACSubtype', 'ACRegistration', 'mel', 'level', 'defectFound', 'openDate', 'seatCategory'];

  public dataSourceOpLim: any;

  public dataLimops: any;

  public hideDetail: boolean = true;
  public type: any;
  public codeBar: string;

  private dataForExportOpLim: any;
  public dataSourceMainPro: any;
  private dataForExportMainPro: any;
  public dataSourcePassCon: any;
  private dataForExportPassCon: any;

  operational: string = "no-report";
  passenger: string = "no-report";
  maintenance: string = "no-report";

  @Output() closeModalEvent = new EventEmitter();
  @Output() loadIsFinished = new EventEmitter<SubcomponentState>();

  constructor(private limopsReportService: LimopsReportService, public dialog: MatDialog) { }

  ngOnInit() {
    let limopsRequest: LimopsRequest = new LimopsRequest();
    limopsRequest.aircraftRegistration = [this.aircraftRegistration];
    limopsRequest.defectTypes = ["PCI", "O", "M"];
    limopsRequest.aircraftSubtype = [this.aircraftSubtype.replace(/[^0-9]+/g, "")];

    this.limopsReportService.getLimopsReport(limopsRequest).subscribe({
      next: resp => {
        this.dataLimops = resp;
        if (resp.maintenanceProceduresReport.length == 0 &&
          resp.operationalLimitationsReport.length == 0 &&
          resp.passengerConvenienceItemsReport == 0) {

          this.loadIsFinished.emit(new SubcomponentState(this, 'not-available'));
          return;
        }
        this.fillTable(resp);
        this.loadIsFinished.emit(new SubcomponentState(this, 'success'));
      },
      error: err => {
        console.error('Error at LimopsReportComponent ngOnInit: ', err);
        this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
      }
    });

  }

  public reportOpenModal(type: string, codeBar: string) {
    this.type = type;
    this.codeBar = codeBar;
    this.hideDetail = false;
  }

  fillTable(data: LimopsResponse) {

    if (data.operationalLimitationsReport.length != 0) {
      this.dataForExportOpLim = data.operationalLimitationsReport.map(lm => [lm.barCode, lm.mocNumber, lm.aircraftSubType, lm.aircraftRegistration, lm.mel, lm.procedure, lm.melLevel, lm.defectFound, lm.openDate, lm.restriction, lm.etops, lm.cat, lm.rvsm, lm.rnav, lm.rnp, lm.cargoAffect]);

      this.dataSourceOpLim = new MatTableDataSource(data.operationalLimitationsReport);
      this.dataSourceOpLim.paginator = this.paginator;
      if (this.dataSourceOpLim.paginator) {
        this.dataSourceOpLim.paginator.firstPage();
      }
      this.operational = "report";
    } else {
      this.dataForExportOpLim = [];
      this.operational = "no-report";
    }


    if (data.maintenanceProceduresReport.length != 0) {
      this.dataForExportMainPro = data.maintenanceProceduresReport.map(lm => [lm.barCode, lm.mocNumber, lm.aircraftSubType, lm.aircraftRegistration, lm.mel, lm.melLevel, lm.defectFound, lm.openDate, lm.actionRequired, lm.repetetiveInspection]);

      this.dataSourceMainPro = new MatTableDataSource(data.maintenanceProceduresReport);
      this.dataSourceMainPro.paginator = this.paginator;
      if (this.dataSourceMainPro.paginator) {
        this.dataSourceMainPro.paginator.firstPage();
      }
      this.maintenance = "report";
    } else {
      this.dataForExportMainPro = [];
      this.maintenance = "no-report";
    }


    if (data.passengerConvenienceItemsReport.length != 0) {
      this.dataForExportPassCon = data.passengerConvenienceItemsReport.map(lm => [lm.barCode, lm.mocNumber, lm.aircraftSubType, lm.aircraftRegistration, lm.mel, lm.melLevel, lm.defectFound, lm.openDate, lm.seatCategory]);

      this.dataSourcePassCon = new MatTableDataSource(data.passengerConvenienceItemsReport);
      this.dataSourcePassCon.paginator = this.paginator;
      if (this.dataSourcePassCon.paginator) {
        this.dataSourcePassCon.paginator.firstPage();
      }
      this.passenger = "report";
    } else {
      this.dataForExportPassCon = [];
      this.passenger = "no-report";
    }

  }

  closeDetailViewModal(e) {
    this.hideDetail = true;
  }


  closeModal() {
    this.closeModalEvent.emit();
  }

  getPDF(): string {

    let doc = new jsPDF('l', 'pt', 'a3');
    doc.setFontSize(18);
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

    let img = new Image()
    img.src = 'assets/img/navbar/logo-latam-pilot.jpg';
    doc.addImage(img, 'png', pageWidth - 175, 20, 125, 50);


    let utcDate = new Date().toUTCString();
    doc.setTextColor('gray');
    doc.text('Document Generation Date: ' + utcDate, pageWidth / 2, 50, { align: 'center' });

    doc.setTextColor('black');
    let startTables = 110;
    doc.text('Operational Limitations Report', pageWidth / 2, startTables - 15, { align: 'center' });
    doc.setFontSize(12);
    (doc as any).autoTable({
      head: [['Bar Code', 'Moc No', 'A/C Subtype', 'A/C Registration', 'MEL', 'Procedure', 'Level', 'Defect Found', 'Open Date', 'Equipment', 'Etops', 'CAT', 'RVSM', 'RNAV', 'RNP', 'Cargo Affect']],
      body: this.dataForExportOpLim,
      startY: startTables,
      theme: 'striped',
      bodyStyles: {
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
      },
      headStyles: {
        fillColor: [27, 0, 136],
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
      }
    });
    let finalY = (doc as any).lastAutoTable.finalY;// The y position on the page
    if (this.dataForExportOpLim.length == 0) {
      doc.setFontSize(12);
      doc.setTextColor('gray');
      doc.text("There are no operational limitations to report", pageWidth / 2, finalY + 15, { align: 'center' });

    }

    finalY = finalY + 60;
    doc.setFontSize(18);
    doc.setTextColor('black');
    doc.text("Maitenance Procedures Report", pageWidth / 2, finalY, { align: 'center' });

    doc.setFontSize(12);
    (doc as any).autoTable({
      head: [['Bar Code', 'Moc No', 'A/C Subtype', 'A/C Registration', 'MEL', 'Level', 'Defect Found', 'Open Date', 'Action Required', 'Repetetive Inspection']],
      body: this.dataForExportMainPro,
      startY: finalY + 15,
      theme: 'striped',
      bodyStyles: {
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
      },
      headStyles: {
        fillColor: [27, 0, 136],
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
      }
    });

    let finalY2 = (doc as any).lastAutoTable.finalY;// The y position on the page

    if (this.dataForExportMainPro.length == 0) {
      doc.setFontSize(12);
      doc.setTextColor('gray');
      doc.text("There are no operational limitations to report", pageWidth / 2, finalY2 + 15, { align: 'center' });
    }

    finalY2 = finalY2 + 60;
    doc.setFontSize(18);
    doc.setTextColor('black');
    doc.text("Passenger Convenience Items Report", pageWidth / 2, finalY2, { align: 'center' });

    doc.setFontSize(12);
    (doc as any).autoTable({
      head: [['Bar Code', 'Moc No', 'A/C Subtype', 'A/C Registration', 'MEL', 'Level', 'Defect Found', 'Open Date', 'Seat Category']],
      body: this.dataForExportPassCon,
      startY: finalY2 + 15,
      theme: 'striped',
      bodyStyles: {
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
      },
      headStyles: {
        fillColor: [27, 0, 136],
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
      }
    });

    let finalY3 = (doc as any).lastAutoTable.finalY;// The y position on the page

    if (this.dataForExportPassCon.length == 0) {
      doc.setFontSize(12);
      doc.setTextColor('gray');
      doc.text("There are no operational limitations to report", pageWidth / 2, finalY3 + 15, { align: 'center' });
    }

    doc.setTextColor('black');
    doc.text("This document is generated exclusively by PILOT", pageWidth / 2, pageHeight - (doc as any).lastAutoTable.settings.margin.top, { align: 'center' });
    doc.setGState(new GState({ opacity: 0.1 }));
    doc.addImage(img, 'png', (pageWidth / 2) - 200, (pageHeight / 2) - 80, 400, 160);
    doc.setGState(new GState({ opacity: 1 }));

    return doc.output('datauristring').split(',')[1];
  }

}
