<mat-progress-bar mode="query" class="progress-bar-home" *ngIf="loadingNewsletter"
    style="margin: 0 auto"></mat-progress-bar>

<div class="row" *ngIf="dataType.section == 'Operational'">
    <div class="col-lg-12">
        <app-alerts [text]="'These documents will stay in PILOT for one month, then will be available in CONTENT'"
            [class]="'w-100'">
        </app-alerts>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <app-alerts *ngIf="newsletters?.length==0" [text]="'There`s no news availables'" [class]="'w-100'">
        </app-alerts>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <mat-form-field class="input-100">
            <mat-select placeholder="Type" #typeFilterSelected (selectionChange)="applyFilter();">
                <mat-option *ngFor="let typeNew of typesNews" [value]="typeNew.type">
                    {{typeNew.type}}
                </mat-option>
            </mat-select>
            <mat-error>
                Type
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field class="content-filter input-100">
            <input matInput (keyup)="applyFilter()" #allFilter placeholder="Search">
        </mat-form-field>
    </div>
</div>
<mat-card appearance="outlined" class="card-newsletter" [ngClass]="newsletter.acknowledge?'acknowledge':''" *ngFor="let newsletter of newsletters">
    <div>
        <div class="container-newsletter" >
            <div>
                <span class="number-plublish">N°{{newsletter.id}}</span>
            </div>
            <div>
                <p class="publish-date">{{newsletter.publishingDate| date : 'mediumDate'}}</p>
            </div>
        </div>
        <div class="container-newsletter titleNews">
            <div>
                <div class="content-title-newsletter">
                    {{newsletter.title}}
                </div>
            </div>
            <div>
                <span class="circle" [ngClass]="newsletter.acknowledge?'c-acknowledge':'c-no-acknowledge'" ></span>
            </div>
        </div>
        <div class="footer-newsletter">
            <div class="container">
                <div class="row">
                    <div class="col-12 title-footer">
                        <span class="title-type">
                            <mat-chip-listbox aria-label="Fish selection">
                                <!-- Safety -->
                                <mat-chip-option color="warn" selected (click)="openNewsletter(newsletter)"
                                    *ngIf="newsletter.typeName=='Safety Alert'">{{newsletter.typeName}}</mat-chip-option>
                                <mat-chip-option color="primary" selected (click)="openNewsletter(newsletter)"
                                    *ngIf="newsletter.typeName=='Nuestros Casos' || newsletter.typeName=='Nossos casos'">{{newsletter.typeName}}</mat-chip-option>
                                <mat-chip-option color="accent" selected (click)="openNewsletter(newsletter)"
                                    *ngIf="newsletter.typeName=='Informativos de seguridad' || newsletter.typeName=='Informativo de Segurança'">{{newsletter.typeName}}</mat-chip-option>

                                <!-- Operational -->
                                <mat-chip-option color="primary" selected (click)="openNewsletter(newsletter)"
                                    *ngIf="newsletter.typeName=='RTA'">{{newsletter.typeName}}</mat-chip-option>
                                <mat-chip-option color="accent" selected (click)="openNewsletter(newsletter)"
                                    *ngIf="newsletter.typeName=='RTM'">{{newsletter.typeName}}</mat-chip-option>
                                <mat-chip-option color="warn" selected (click)="openNewsletter(newsletter)"
                                    *ngIf="newsletter.typeName=='E&I'">{{newsletter.typeName}}</mat-chip-option>
                            </mat-chip-listbox>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>