<main class="pt-5 mt-5 container">
    <div class="top-nextflight">
        <span class="btn-back clickable d-flex" (click)="closeFlightInformation()"><i class="material-icons">chevron_left</i> {{origin}}</span>
        <h2>Statistics for this route</h2>
    </div>

    <roster-fcard class="next-flight" *ngIf="flight != undefined" [activity]="flight" [origin]='origin'></roster-fcard>

    <div class="crew-briefin" *ngIf="hasRPT">
        <app-crew-briefing [loadingCrewBriefing]="loadingCrewBriefing" [dataCrewBriefing]="dataCrewBriefing" [isFromBrasil]="isFromBrasil"></app-crew-briefing>
    </div>

    <mat-card appearance="outlined" class="card-crew">
        <div class="loading-spinner" *ngIf="loadCrew">
            <mat-spinner strokeWidth="10" [diameter]="100" style="margin: 0 auto" class="custom-size"></mat-spinner>
        </div>
        <mat-tab-group *ngIf="!loadCrew">
            <mat-tab class="title-trip" label="TRIP">
                <table *ngIf="flightCrewAssignment.length > 0" class="table" aria-describedby="pilot table">
                    <thead>
                        <tr>
                            <th scope="col" class="t-gray-4 latam_bold">
                                Crew on board: {{flightCrewAssignment?.length - paxCnt}}
                                <span class="pax-board"> Pax on board: {{ paxCnt }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let crew of flightCrewAssignment">
                            <td class="content-crew">
                                <div class="type-crew">{{crew.category}}</div>
                                <div class="circle-pax" *ngIf="crew.isPax">PAX</div>

                                <div class="operator-original">
                                    <p class="t-gray-4">
                                        <span *ngIf="crew.nacionality == 'BR' && crew.warName !== undefined && crew.warName !== null">{{crew.warName}}</span>
                                        <span *ngIf="crew.nacionality == 'BR' && crew.warName == undefined || crew.warName == null">{{crew.name}} {{crew.lastName}}</span>
                                        <span *ngIf="crew.nacionality !== 'BR'">{{crew.name}} {{crew.lastName}}</span>
                                    <br>
                                    <span class="latam_bold">
                                        BP: {{crew.bp}}
                                    </span>
                                    <span class="latam_bold" *ngIf="crew.documentNumber !== undefined && crew.documentNumber !== null && crew.nacionality !== 'BR' ">
                                        / DOC: {{crew.documentNumber}}
                                    </span>
                                    <span class="latam_bold" *ngIf="crew.codAnac !== undefined && crew.codAnac !== null">
                                        / ANAC: {{crew.codAnac}}
                                    </span>
                                    <br *ngIf="!crew.isPax">
                                    <span class="latam_bold" *ngIf="!crew.isPax">
                                        / Report Time: {{crew.reportTime}}
                                    </span>
                                    <br>
                                    <i><u><a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{crew.email}}&su=" target="_blank" rel="noopener noreferrer" >{{crew.email}}</a></u></i>
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="no-data-yet" *ngIf="flightCrewAssignment.length == 0">
                    <app-alerts
                      [text]="'Crew information not found. Please connect your device to a network'" [class]="'w-100'"
                      [type]="'warning'">
                    </app-alerts>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</main>
