import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lowStabilization',
    standalone: false
})
export class LowStabilizationPipe implements PipeTransform {

  transform(value: number): string {
    if(value < 1000) {
      return "Please refer to Safety"
    }else if(value == null){
      return "-"
    }else {
      return value.toString();
    }
  }

}
