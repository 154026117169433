import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    IonicModule,
    MatCardModule,
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {

  private router: Router = inject(Router);

  goHome() {
    this.router.navigate(['/home']);
  }

}
