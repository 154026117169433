import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProfileService } from "../../services/profile.service";
import { LicensesByState } from "../../domain/profile/licenses-by-state.model";
import { CategoryState } from "../../domain/profile/category-state.model";
import { DocumentsByState } from "../../domain/profile/documents-by-state.model";
import { PilotConstants } from "../../commons/pilot-constants";
import Swal from "sweetalert2";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { License } from "src/app/modules/pilot/domain/profile/license.model";
import { finalize } from "rxjs/operators";
import { IDocumentsType } from "src/app/modules/pilot/domain/profile/documents-pilot";
import { Subscription } from "rxjs";
import { UiService } from "src/app/modules/pilot/services/ui/ui.service";
import { UserService } from "src/app/core/services/user/user.service";
import * as moment from "moment";
import { RemoteConfigService } from "../../services/remote-config/remote-config.service";
import { PreferencesService } from "src/app/core/services/preferences/preferences.service";
import { CommonsService } from "src/app/core/services/commons/commons.service";


@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    standalone: false
})
export class ProfileComponent implements OnInit, OnDestroy {

  orderedDocuments: DocumentsByState = new DocumentsByState();
  orderedLicenses: LicensesByState = new LicensesByState();
  orderedVisas: DocumentsByState = new DocumentsByState();
  orderedPassports: DocumentsByState = new DocumentsByState();

  categories: Array<CategoryState> = [];

  selectedIndex: number = 0;
  selectedLabel: string;
  loading: boolean;
  showUploadModal: boolean = false;
  showAlertDocumentsUploaded: boolean = false;
  typeDocument: IDocumentsType;
  refreshSubs: Subscription;

  allowedOperatorsDocuments: { [key: string]: string[]};
  contractCodeOperator: string = '';
  documentsAllowedByOperators: { [key: string]: boolean };
  
  constructor(
    private profileService: ProfileService,
    public constants: PilotConstants,
    private _ui: UiService,
    private _user: UserService,
    private remoteConfig: RemoteConfigService,
    private _preferences: PreferencesService,
    private commonsService: CommonsService
  ) { }
  
  ngOnInit() {
    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh)  {
        this.init(true);
      };
    });
    this.init();
  }

  ngOnDestroy(): void {
    this.refreshSubs?.unsubscribe();
  }

  async init(refresh: boolean = false) {
    this.loading = true;
    this.initCategories();
    let user = this._user.getUserValue;
    this.contractCodeOperator = user?.profile.contractCode;
    
    if (refresh) await this._preferences.removeItem('allowed_operators_documents');
    
    this.getConfigDocumentsAllowedByOperators();
    //Service call to get documents
    this.profileService.getMyDocuments()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: resp => {
          //Filtering documents
          let documents = resp.crewInformation.identificationDocuments.filter(id => id.typeCode == "NATIONAL ID DOCS");
          let passports = resp.crewInformation.identificationDocuments.filter(id => id.typeCode == this.constants.RULES_OF_PROFILE_DOCUMENTS.PASSPORT.name);
          let licenses = resp.crewInformation.licenses;
          let visas = resp.crewInformation.identificationDocuments.filter(id => id.typeCode == this.constants.RULES_OF_PROFILE_DOCUMENTS.VISAS.name);

          //MAE license to documents
          licenses.push(...resp.crewInformation.qualifications
            .filter(q => q.typeCode == 'MAE')
            .map(q => new License(q.typeCode, null, null, q.isoCountryCode, q.expirationDate, q.issueDate))
          );

          //Grouping data by expiration date, by leading 3 groups: active documents, expired documents and documents next to expire
          this.groupingDataByExpirationDate(documents, this.orderedDocuments, this.constants.RULES_OF_PROFILE_DOCUMENTS.DOCUMENTS.expirationRule);
          this.groupingDataByExpirationDate(passports, this.orderedPassports, this.constants.RULES_OF_PROFILE_DOCUMENTS.PASSPORT.expirationRule);
          this.groupingDataByExpirationDate(licenses, this.orderedLicenses, this.constants.RULES_OF_PROFILE_DOCUMENTS.LICENSES.expirationRule);
          this.groupingDataByExpirationDate(visas, this.orderedVisas, this.constants.RULES_OF_PROFILE_DOCUMENTS.VISAS.expirationRule);

          //Notifications to show the total of next to expire and expired documents
          this.setNotificationsForCategory(this.constants.RULES_OF_PROFILE_DOCUMENTS.DOCUMENTS.name, this.orderedDocuments);
          this.setNotificationsForCategory(this.constants.RULES_OF_PROFILE_DOCUMENTS.PASSPORT.name, this.orderedPassports);
          this.setNotificationsForCategory(this.constants.RULES_OF_PROFILE_DOCUMENTS.LICENSES.name, this.orderedLicenses)
          this.setNotificationsForCategory(this.constants.RULES_OF_PROFILE_DOCUMENTS.VISAS.name, this.orderedVisas);
          refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
        },
        error: err => {
          refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
          Swal.fire({ icon: 'error', title: 'Error', text: 'Ooops! There was a problem getting the documents. Please try again.'})
        }
    });
  }

  async getConfigDocumentsAllowedByOperators() {
    let key = 'allowed_operators_documents';
    let value = await this._preferences.getItem(key);
    this.allowedOperatorsDocuments = JSON.parse(value);
    if (this.allowedOperatorsDocuments) {
      this.validateAllowDocument();
      return;
    }
    this.remoteConfig.getParameterRemoteConfig('allowed_operators_documents').subscribe(
      resp => {
        if (resp) {
          this._preferences.setItem(key, resp);
          this.allowedOperatorsDocuments = resp;
          this.validateAllowDocument();
        }
      }
    );
  }

  validateAllowDocument() {
    this.documentsAllowedByOperators = {};
    this.showAlertDocumentsUploaded = false;
    for (const document in this.allowedOperatorsDocuments) {
      this.documentsAllowedByOperators[document] =this.allowedOperatorsDocuments[document].includes(this.contractCodeOperator);
      if (this.documentsAllowedByOperators[document]) {
        this.showAlertDocumentsUploaded = true;
      }
    }
  }

  /*To manage lists: grouping data*/
  groupingDataByExpirationDate(unorderedList: any[], orderedList: DocumentsByState| LicensesByState, rule: number) {

    let today = moment().format('YYYY-MM-DD');
    let thresholdDate = moment().add(rule, "months").format('YYYY-MM-DD');
    unorderedList.forEach(doc => {
      //undefined or null
      if (!doc.expirationDate) {
        orderedList.active.push(doc);
        return;
      }

      const expirationMoment = moment(doc.expirationDate, 'YYYY-MM-DD');
      
      if (expirationMoment.isSameOrBefore(today)) {
        orderedList.expired.push(doc);
      }
      else if (expirationMoment.isBetween(today,thresholdDate, 'day', '[]')) {
        orderedList.nextToExpire.push(doc);
      } else {
        orderedList.active.push(doc);
      }
    });
    orderedList.active = orderedList.active.sort(this.SortArray);
  }

  /*To manage lists: check if ordered list is empty to show a "no information" message*/
  orderedListIsEmpty(orderedList: DocumentsByState| LicensesByState){
    return orderedList.active.length == 0 && orderedList.expired.length == 0 && orderedList.nextToExpire.length == 0;
  }

  /*To manage lists: set notifications for every state and every category*/
  setNotificationsForCategory(categoryName: string, orderedList){
    let category = this.categories.find(category => category.name == categoryName);
    category.totalExpired = orderedList.expired.length;
    category.totalNextToExpire = orderedList.nextToExpire.length;
  }

  /*2 methods to init categories*/
  initList(orderedList: DocumentsByState| LicensesByState){
    orderedList.active = [];
    orderedList.expired = [];
    orderedList.nextToExpire = [];
  }

  initCategories(){
    this.initList(this.orderedDocuments);
    this.initList(this.orderedLicenses);
    this.initList(this.orderedPassports);
    this.initList(this.orderedVisas);
    this.categories = [];
    this.categories.push(new CategoryState(this.constants.RULES_OF_PROFILE_DOCUMENTS.PASSPORT.name, true));
    this.categories.push(new CategoryState(this.constants.RULES_OF_PROFILE_DOCUMENTS.DOCUMENTS.name, false));
    this.categories.push(new CategoryState(this.constants.RULES_OF_PROFILE_DOCUMENTS.LICENSES.name, false));
    this.categories.push(new CategoryState(this.constants.RULES_OF_PROFILE_DOCUMENTS.VISAS.name, false));
  }

  onSelectedTabChange(matTabChangeEvent: MatTabChangeEvent)  {
    this.selectedLabel = matTabChangeEvent.tab.textLabel.toUpperCase();
  }

  SortArray(x, y){
    if (x.expirationDate === null)
     {return 1;}
 
    if (y.expirationDate === null ) 
     {return -1;}  

    if (x.expirationDate < y.expirationDate)
     {return -1;}

    if (x.expirationDate > y.expirationDate) 
     {return 1;}

     return 0;
  }

  showModal(typeDocument: IDocumentsType) {
  this.commonsService.registryEvent({ category: 'profile_documents', event: 'open_modal_upload_document_' + this.typeDocument});
    this.showUploadModal = !this.showUploadModal;
    this.typeDocument = typeDocument;
  }

}
