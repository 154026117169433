import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { IndexeddbService } from '../../services/indexeddb.service';
import { RolService } from '../../services/rol.service';
import { CommonsService } from '../../../../core/services/commons/commons.service';
import { SetFuelSafetyService } from 'src/app/modules/pilot/services/set-fuel-safety.service';
import { DateToBackPipe } from 'src/app/modules/pilot/commons/pipes/date-to-back.pipe';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RosterCommon } from 'src/app/modules/pilot/commons/roster-common';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { Subscription, timer } from 'rxjs';
import { BriefingTmComponent } from './briefing-tm/briefing-tm.component';
import { FlightInformationComponent } from './flight-information/flight-information.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { Activity } from 'src/app/modules/pilot/domain/roster/activity.model';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { GateBridgeRequest } from '../../domain/GateBridgeRequest';

@Component({
    selector: 'app-rol',
    templateUrl: './rol.component.html',
    styleUrls: ['./rol.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class RolComponent implements OnInit, OnDestroy {
  activities: any;
  profile: any;
  loadingRoster: boolean = true;
  notAvailable: boolean;
  indexLastActivity: number;
  nf_index: number;

  showMessageUpdated: boolean = false;
  checked: boolean = false;
  isFromBrasil: boolean = false;
  textTimezoneLeft: string;
  textTimezoneRight: string;

  selectedSection: number = 0;
  primary = '';

  @ViewChild(BriefingTmComponent) btmcomponent: BriefingTmComponent;
  @ViewChild(FlightInformationComponent) flightInfoComponent: FlightInformationComponent;

  source = timer(0, 1000*20);
  clock: any;
  end: any;
  rosterDate: any;
  briefingDate: any;
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;

  btmHasData: boolean = false;
  rosterHasActivities: boolean = false;
  rosterHasFlights: boolean = false;
  rosterHasData: boolean = false;

  rosterLastUpdate: string;
  briefingLastUpdate: string;

  rosterUpdate: string = "0 mins ago";
  briefingUpdate: string = "0 mins ago";

  public showFlightInformation: boolean = false;
  user: IUser;
  userSubs: Subscription;
  refreshSubs: Subscription;

  // Data to show flight information || crew
  flightSelected: Activity;
  origin = "roster";

  constructor(
    private indexeddbService: IndexeddbService,
    private rolService: RolService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private commonsService:CommonsService,
    protected setFuelSafetyService: SetFuelSafetyService,
    protected _network: NetworkService,
    protected dateBack: DateToBackPipe,
    private rosterCommon: RosterCommon,
    private _user: UserService,
    private _preferences: PreferencesService,
    public constants: PilotConstants,
    private _ui: UiService
  ) {
  }

  async ngOnInit() {
    await this.rosterCommon.monitorNetWorkState();
    this.userSubs = this._user.getUser().subscribe((user) => {
      this.user = user;
      this.profile = this.user?.profile;
      if (this.profile?.operator == "JJ" || this.profile?.operator == "M3") {
        this.isFromBrasil = true;
        this.textTimezoneRight = "LT Airport";
        this.textTimezoneLeft = "LT Brasilia";
      } else {
        this.isFromBrasil = false;
        this.textTimezoneLeft = "LT Airport";
        this.textTimezoneRight = "UTC";
      }
      if (this.rosterCommon.isConnected) {
        this.updateCrew(false);
      } else {
        this.loadDataRoster();
      }
    });

    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh) {
        this.updateCrew(false);
        this.refreshBriefing();
      };
    });

    this.rosterLastUpdate = '...';
    this.briefingLastUpdate = '...';

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.section) {
        this.redirectToTab(params.section);
      }
    });


    this.clock = this.source.subscribe(t => {

      if (t >0){
        this.rosterDate = moment(this.rosterLastUpdate, 'DD/MMM/YY HH:mm');
        this.briefingDate= moment(this.briefingLastUpdate, 'DD/MMM/YY HH:mm');
        this.end = moment(moment().utc().format('DD/MMM/YY HH:mm'), 'DD/MMM/YY HH:mm');
        this.showDate();
      }
    });

    this.commonsService.registryEvent({ category: 'Roster', event: 'view_roster_section'});
  }

  ngOnDestroy(): void {
    this.userSubs?.unsubscribe();
    this.refreshSubs?.unsubscribe();
  }

  showDate(){
    let differenceRoster = moment.duration(this.end.diff(this.rosterDate));

    let differencebriefing = moment.duration(this.end.diff(this.briefingDate));

    this.rosterUpdate= this.calculateHour(differenceRoster.get("hours"), differenceRoster.get("minutes"));
    this.briefingUpdate= this.calculateHour(differencebriefing.get("hours"), differencebriefing.get("minutes"));

  }

  calculateHour(hours: any, minutes: any){
    if(hours > 0 && minutes > 9){
      return hours + ":" +  minutes + " hours ago";
    }else if(hours > 0 && minutes <= 9){
      return hours + ":0" + minutes + " hours ago";
    }
    else{
     return minutes + " mins ago";
    }
  }

  refreshBriefing(){
    this.loadingRoster = true;
    this.btmcomponent.updateBriefing();
  }

  update(updateFromBTM: boolean) {
    this.loadingRoster = updateFromBTM;
    this.briefingUpdate = "0 mins ago";
  }

  loadDataRoster() {
    this._preferences.getItem('last-roster-update').then( value => {
      this.rosterLastUpdate = value;
    });
    this.setData();
  }

  async setData(activities?: Array<Activity>) {
    if (!activities) {
      this.activities = await this.indexeddbService.getDataFromTable('rol', 'activities').then(response => {
        this.loadingRoster = false;
        this.nf_index = this.rolService.getNextFlight(response).index;
        return this.filterRoster(response);
      });
    } else {
      let { flight, index } = this.rolService.getNextFlight(activities);
      this.nf_index = index;
      if (flight) {
        let gate = await this.rolService.getGate(flight);
        activities[index].flightLeg.gate = gate;
      }

      this.activities = this.filterRoster(activities);
      this.loadingRoster = false;
      this.indexeddbService.addToTable('rol', this.activities, "activities");

      this.rosterCommon.saveCrewInformation(this.activities);
    }
  }

  filterRoster (roster) {

    this.rosterHasFlights = roster?.filter(rElement => rElement.code == "LEG").length != 0;
    this.rosterHasActivities = roster?.filter(rElement => rElement.code != "LEG").length != 0;

    let filteredRoster = roster?.filter(activity => {
      let today = moment();
      let dateActivity;

      if (activity.code == "LEG") {
        dateActivity = moment(activity.flightLeg.departure.dates.local, "YYYY-MM-DD HH:mm:ss");

      } else {
        dateActivity = moment(activity.localDate, "DD/MM/YYYY");

        if(!activity.description){
          return false;
        }
      }
      let diff = dateActivity.diff(today, 'days') + 1;

      activity.before7Days = diff <= 7;
      return true;
    });

    this.rosterHasData = filteredRoster?.length != 0;

    this.indexLastActivity = roster?.findIndex(rElement => !rElement.before7Days);
    return filteredRoster;
  }

  updateCrew(clickButton: boolean) {
    this.loadingRoster = true;
    if(clickButton){
      this.commonsService.registryEvent({ category: 'Roster', event: 'update_roster_section'});
    }
    let operator = this.profile?.contractCode?? this.profile?.operator;
    this.rolService.getRoster(operator, this.rosterCommon.getSearchDates('YYYY-MM-DD'))
    .pipe(finalize(() => this._preferences.getItem('last-roster-update').then( value => this.rosterLastUpdate = value)))
    .subscribe({
      next: async resp => {
        this.rosterUpdate = "0 mins ago";
        this._preferences.setItem('last-roster-update', moment().utc().format('DD/MMM/YY HH:mm'));
        let newRoster = await this.keepDispatchIdsAndSaveRoster(resp);
        this.setData(newRoster);
        this.showMessageUpdated = true;
        setTimeout(() => this.showMessageUpdated = false, 5000);
        this._ui.setStateRefresh({ refresh: false, finishEvent: true });
      },
      error :err => {
        this._ui.setStateRefresh({ refresh: false, finishEvent: true });
        this.loadDataRoster();
        this.loadingRoster = false;
        console.log(err);
    }});
  }

  private keepDispatchIdsAndSaveRoster(newRoster: Array<Activity>) {
    return new Promise<Array<Activity>>((resolve, reject) => {
      this.indexeddbService.getDataFromTable('rol', 'activities').then((oldRoster: Array<Activity>) => {
        //Only flights with dispatch id
        oldRoster?.map(f => Object.assign(new Activity(), f)).filter(f => f.dispatchId != null)
          .forEach((oldFlight, index) => {

            newRoster.filter(newFlight =>
              newFlight.flightLeg != null &&
              oldFlight.equalFlight(
                newFlight.flightLeg.flightNumber,
                newFlight.flightLeg.departure.iataCode,
                moment(newFlight.flightLeg.departure.dates.utc).format('YYYY-MM-DD'),
                newFlight.flightLeg.carrier)).forEach(f => {
                  f.dispatchId = oldFlight.dispatchId;
                });
          });
        resolve(newRoster);
      });
    })
  }

  checkIfBtmHasData(hasData: boolean){
    this.btmHasData = hasData;
    this._preferences.getItem('last-briefing-update').then( value => {
      this.briefingLastUpdate = value;
    });
  }

  redirectToTab(section: string = 'all'){

    let sectionNameToTabIndex = {
      'all': 0,
      'briefing': 1,
      'flights': 2,
      'activities': 3
    };

    this.selectedSection = sectionNameToTabIndex[section];

    //Save analytics
    this.commonsService.registryEvent({ category: 'Roster', event: 'view_roster_section_' + section});
  }

  markSection(index: number){
    let toSectionName = ['all', 'briefing', 'flights', 'activities'];
    this.router.navigate([],{ relativeTo: this.activatedRoute, queryParams: { section: toSectionName[index] } });
  }

  openFlightInformation(flight: Activity, origin: string){
    this.showFlightInformation = true;
    this.flightSelected = flight;
  }

  closeFlightInformation(){
    this.showFlightInformation = false;
  }
}
