import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { LoadSheetACK } from 'src/app/modules/pilot/domain/dispatch/loadsheetACK.model';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { confirmAlert } from 'src/app/core/utils/utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-ack-confirm',
    templateUrl: './ack-confirm.component.html',
    styleUrls: ['./ack-confirm.component.css'],
    standalone: false
})
export class AcknowledgeComponent implements OnInit, OnDestroy {

  loadsheetACK: LoadSheetACK;
  public ackForm: FormGroup;
  disclaimer: string = this.constants.MESSAGES_APP.DISPATCH_FOLDER.LOADSHEET.ACK;
  hiddenDisclaimersForOperators = this.constants.OFICIAL_LOADSHEET_OPERATORS;
  showDisclaimer: boolean = true;
  isTestEnvironment: boolean = !environment.production;
  @Input() operator: string;
  @Input() zfw: string;
  @Input() tow: string;
  @Input() law: string;
  @Input() undld: string;
  @Input() user: string;


  constructor(
    private _ui: UiService,
    public constants: PilotConstants,
    private _fb: FormBuilder,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this._ui.listenKeyboard('modal-ack-loadsheet');
    this.showDisclaimer = !this.hiddenDisclaimersForOperators.includes(this.operator);
    this.ackForm = this._fb.group({
      comment: [''],
    }, { updateOn: 'blur' });
  }

  ngOnDestroy(): void {
    this._ui.removeKeyboardListeners();
  }

  closeClick() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  async performAck(ack: 1 | -1) {
    this.loadsheetACK = new LoadSheetACK();
    if (ack == -1) {
      let confirmAction = await confirmAlert({
        title: `Reject loadsheet`,
        text: `Are you sure you want to reject this loadsheet?`,
        icon: 'warning',
        confirmButtonText: `Yes, reject`,
        cancelButtonText: 'Cancel',
        allowOutsideClick: false
      }, true);
      if (!confirmAction) return;
    }

    this.loadsheetACK.ack = ack;
    this.loadsheetACK.comment = this.ackForm.get('comment').value;
    
    return this.modalCtrl.dismiss(this.loadsheetACK, 'action');
  }

}
