<div *ngIf="innerWidth <= 775 && activity.flightLeg == null">
  <mat-card appearance="outlined" class="activity-card">
    <div class="activity-card-header">
      <div class="hour-labels">
        <div class="start-time">
          <span class="sub-label" *ngIf="(!checked && !isFromBrasil) || (checked && isFromBrasil)">{{ activity.dates.localStart | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="checked && !isFromBrasil">{{ activity.dates.utcStart | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="!checked && isFromBrasil">{{ activity.dates.brasilStart | date : 'HH:mm' }}</span>
        </div>
        <div class="separator">-</div>
        <div class="end-time">
          <span class="sub-label" *ngIf="(!checked && !isFromBrasil) || (checked && isFromBrasil)"> {{ activity.dates.localEnd | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="checked && !isFromBrasil"> {{ activity.dates.utcEnd | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="!checked && isFromBrasil"> {{ activity.dates.brasilEnd | date : 'HH:mm' }}</span>
        </div>
      </div>
      <div class="date-cont">
        <p *ngIf="(!checked && !isFromBrasil) || (checked && isFromBrasil)">{{activity.dates.localStart | date : 'MMMM d EEE' | titlecase}}</p>
        <p *ngIf="checked && !isFromBrasil">{{activity.dates.utcStart | date : 'MMMM d EEE' | titlecase}}</p>
        <p *ngIf="!checked && isFromBrasil">{{activity.dates.brasilStart | date : 'MMMM d EEE' | titlecase}}</p>
      </div>
    </div>
    <div class="description">
      <span class="description-label">{{activity.code}} - {{activity.description | titlecase}}</span>
    </div>
  </mat-card>
</div>

<div *ngIf="innerWidth > 775 && activity.flightLeg == null">
  <mat-card appearance="outlined" class="activity-card-wide">
    <div class="activity-card-header">
      <div class="hour-labels">
        <div class="start-time">
          <span class="sub-label" *ngIf="(!checked && !isFromBrasil) || (checked && isFromBrasil)">{{ activity.dates.localStart | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="checked && !isFromBrasil">{{ activity.dates.utcStart | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="!checked && isFromBrasil">{{ activity.dates.brasilStart | date : 'HH:mm' }}</span>
        </div>
        <div class="separator">-</div>
        <div class="end-time">
          <span class="sub-label" *ngIf="(!checked && !isFromBrasil) || (checked && isFromBrasil)"> {{ activity.dates.localEnd | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="checked && !isFromBrasil"> {{ activity.dates.utcEnd | date : 'HH:mm' }}</span>
          <span class="sub-label" *ngIf="!checked && isFromBrasil"> {{ activity.dates.brasilEnd | date : 'HH:mm' }}</span>
        </div>
      </div>
      <div class="date-cont">
        <p *ngIf="(!checked && !isFromBrasil) || (checked && isFromBrasil)">{{activity.dates.localStart | date : 'MMMM d EEE' | titlecase}}</p>
        <p *ngIf="checked && !isFromBrasil">{{activity.dates.utcStart | date : 'MMMM d EEE' | titlecase}}</p>
        <p *ngIf="!checked && isFromBrasil">{{activity.dates.brasilStart | date : 'MMMM d EEE' | titlecase}}</p>
      </div>
    </div>
    <div class="description">
      <span class="description-label">{{activity.code}} - {{activity.description | titlecase}}</span>
    </div>
  </mat-card>
</div>