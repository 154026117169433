import { Injectable } from '@angular/core';
import { PilotConstants } from '../commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FlightRQ } from '../domain/other-flights/flight-request.model';
import { CrewInformationFlightRS } from '../domain/other-flights/crew-information-flight-rs.model';

@Injectable({
  providedIn: 'root'
})
export class OtherFlightsService {
  public auth2: any;

  constructor(
    private httpClient: HttpClient,
    private constants: PilotConstants
  ) { }

  searchFlights(request: FlightRQ, allFlights: boolean = true): Observable<CrewInformationFlightRS> {
    const allFlightsParam: string = allFlights ? 'giveMeAllFlightsAndShutUp' : 'allFlightsExceptBrasil';
    const URL = `${environment.apiUrl}${this.constants.APPLICATION_ROLE_PATH}${this.constants.REST_V1}${this.constants.SEARCH_FLIGHTS}/${allFlightsParam}`;
    return this.httpClient.post<CrewInformationFlightRS>(URL, request);
  }
}