import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-ranking-airports',
    templateUrl: './ranking-airports.component.html',
    standalone: false
})
export class RankingAirportsComponent {
  
  @Input("rankingOfAirports") rankingOfAirports:any;
  @Input("showLoading") showLoading:any;
  airportList: Array<any>;
  
  ngOnChanges(changes: SimpleChanges): void {
    if(this.rankingOfAirports != undefined && this.rankingOfAirports != null){
      this.setAirportRanking();
    }
  }

  setAirportRanking(){
    this.airportList = this.rankingOfAirports;
  }
  
}
