import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { GroundSupportStatus } from '../domain/dispatch/ground-support-status.model';
import { JoinDocsRequest } from '../domain/dispatch/join-docs-request.model';
import { LoadSheetACK } from '../domain/dispatch/loadsheetACK.model';
import { FlightId } from '../domain/flight-id.model';
import { FPMFlight } from '../domain/dispatch/cae/fpm-flight.model';
import { ILastMinuteChange } from '../domain/dispatch/loadsheet/last-minute-change.interface';
import { ILoadSheetByFlight } from '../domain/loadsheet/loadsheet-by-flight.interface';
import { DispatchZFWRequest } from '../domain/dispatch/DispatchZFWRequest';
import { LoadSheetsComparedACK } from '../domain/dispatch/cosmos/compare-ack-request';
import { MessageDispatchDialogComponentProps } from '../domain/dispatch/loadsheet/message-dispatch-dialog-props.interface';
import { directPilotComment } from '../domain/dispatch/directos/directPilotComment';
import { ModalController } from '@ionic/angular';
import { MessageDispatchDialogComponent } from '../components/dispatch/message-dispatch-dialog/message-dispatch-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DispatchService {

  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient, 
    private constants: PilotConstants,
    private modalCtrl: ModalController
  ) { }

  getCaeDocsByFlight(flight:FlightId, anotherDispatch: boolean):Observable<Map<string, Map<string, string>>>{
    let params = new HttpParams().set('anotherDispatch', anotherDispatch.toString());
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V2}${this.constants.DISPATCH_FOLDER_BASE_PATH}${this.constants.DISPATCH_CAE_DOCS_BY_FLIGHT}`;
    return this.http.post<Map<string, Map<string, string>>>(URL, flight , { params } );
  }

  getCaeDocsByFlightId(flight:FlightId,):Observable<Map<string, Map<string, string>>>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_FOLDER_BASE_PATH}/cae-docs-by-id-and-flight/${flight.dispatchId}`;
    return this.http.post<Map<string, Map<string, string>>>(URL, flight);
  }

  getFlightIds(flights: Array<FlightId>, date: any ) : Observable<Array<FPMFlight>> {
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_FOLDER_BASE_PATH}/flight-ids`;
    return this.http.post<Array<FPMFlight>>(URL, flights , { params: new HttpParams().set('date', date) } );
  }

  getLoadSheet(flight: ILoadSheetByFlight):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V2}${this.constants.DISPATCH_LOADSHEET}`;
    return this.http.post(URL, flight);
  }

  async showLoadSheetModal(componentProps: MessageDispatchDialogComponentProps) {
    const modal = await this.modalCtrl.create({
      component: MessageDispatchDialogComponent,
      componentProps
    });
    modal.present();
    const { role, data } = await modal.onWillDismiss();
    return new Promise<{ role: string, data: any }>((resolve, reject) => {
      resolve({role, data});
    });
  }

  getLoadSheetById(id: number):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_LOADSHEET}/${id}`;
    return this.http.get(URL);
  }
      
  joinDocs(joinDocsRequest: JoinDocsRequest):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V2}${this.constants.DISPATCH_FOLDER_BASE_PATH}${this.constants.DISPATCH_JOIN_DOCS}`;
     return this.http.post(URL, joinDocsRequest);
  }

  async getSignedUrl() {
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_FOLDER_BASE_PATH}${this.constants.DISPATCH_URLFILE}`;
    return await firstValueFrom(this.http.get(URL, { responseType: 'text' }));
  }

  putFileOnBucket(fileToUpload: File, preSignedUrl: string ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('fileKey', new Blob([fileToUpload]));
    return this.http.put(preSignedUrl, formData, {headers: { "Content-Type": "multipart/form-data" }});
  }

  setLoadSheetACK(ackLoadsheet :LoadSheetACK):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V2}${this.constants.DISPATCH_LOADSHEET_ACK}`;
    return this.http.post(URL, ackLoadsheet);
  }

  getGroundSupportStatusReport():Observable<Array<GroundSupportStatus>>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_FOLDER_BASE_PATH}${this.constants.GROUND_SUPPORT_STATUS}`;
    return this.http.get<Array<GroundSupportStatus>>(URL);
  }

  lastMinuteChange(data: ILastMinuteChange): Observable<any> {
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_LOADSHEET}/last-minute-change/${data.id}`;
    return this.http.put(URL, data);
  }

  getZFW(request: DispatchZFWRequest):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_FOLDER_BASE_PATH}${this.constants.DISPATCH_ZFW}`;
    return this.http.post(URL, request);
  }

  getCompareLoadsheetByIds(id1: number, id2: number):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_LOADSHEET_GET_COMPARE_LS}/${id1}/${id2}`;
    return this.http.get(URL);
  }

  setCompareACK(request :LoadSheetsComparedACK):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V2}${this.constants.DISPATCH_LOADSHEET_ACK_COMPARE_LS}`;
    return this.http.post(URL, request);
  }

  getSeatsByFlight(request: any):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.DISPATCH_FOLDER_BASE_PATH}${this.constants.DISPATCH_SEATS_BY_FLIGHT}`;
    return this.http.post(URL, request);
  }

  getMaintenixReport(tail: string):Observable<any>{

    const params = new HttpParams()
    .set('aircraft', tail);
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_BASE_PATH}${this.constants.DISPATCH_MAINTENIX_REPORT}`;
    return this.http.get(URL, { params });
  }

  getDirectos(flight: ILoadSheetByFlight):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_BASE_PATH}${this.constants.DISPATCH_DIRECTS_REPORT}`;
    return this.http.post(URL, flight);
  }


  saveDirectComment(pilotCommet: directPilotComment):Observable<any>{
    const URL = `${this.apiUrl}${this.constants.APPLICATION_DISPATCH_PATH}${this.constants.REST_V1}${this.constants.DISPATCH_BASE_PATH}${this.constants.DISPATCH_DIRECTS_REPORT}${this.constants.DISPATCH_DIRECTS_REPORT_COMMENT}`;
    return this.http.post(URL, pilotCommet);
  }

}
