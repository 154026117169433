export class GateBridgeRequest {
    flightNumber: string;
    departureAirport: string;

    constructor() {
        this.flightNumber = null;
        this.departureAirport = null;
    }
    
}
