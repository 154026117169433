import { Component, OnInit } from '@angular/core';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-reports',
    templateUrl: './reports-guide-modal.component.html',
    styleUrls: ['./reports-guide-modal.component.css'],
    standalone: false
})
export class ReportsGuideModalComponent implements OnInit {

  reasonList: any;

  constructor(
    private _preferences: PreferencesService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.init();
  }

  async init() {
    this.reasonList = JSON.parse(await this._preferences.getItem("reasonList"));
  }

  closeModal() {
    return this.modalCtrl.dismiss("close", 'close');
  }

}
