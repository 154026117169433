<div class="loading-spinner"  *ngIf="loading">
    <mat-spinner strokeWidth="10" [diameter]="100" style="margin: 0 auto" class="custom-size"></mat-spinner>
</div>

<ng-container *ngIf="!loading">
    <div class="action-update m-3">
        <ion-button (click)="getMyFlightLogs(true)" expand="block" fill="outline" size="small">
            <ion-icon slot="start" name="reload-circle-outline"></ion-icon>
            &nbsp;Update My flightlogs &nbsp;
        </ion-button>
    </div>
    <div class="title-main m-3">
        <span class="text-title">New Flight Logs</span>
    </div>

    <div class="m-3">
        <div class="not-found" *ngIf="!flightLogs || flightLogs.recent.length == 0">
            <p class="t-gray-3">{{!flightLogs ? 'Could not load your new flight logs, please try again later' : 'No flight logs'}}</p>
        </div>
        <mat-accordion>
            <ng-container *ngFor="let recent of flightLogs?.recent">
                <mat-expansion-panel class="new-flight-logs">
                    <mat-expansion-panel-header class="panel-header" [collapsedHeight]="'3.5rem'">
                        <mat-panel-title class="mat-panel-title-fl">
                            <img src="/assets/img/rol/flight_cycle.svg" class="mr-1" alt="">
                            <span class="mt-1">{{recent.flightLogNumber}}</span>
                            <div class="position-btn">
                                <button class="btn-outline btn-accept delete-flog-btn" (click)="deleteFlightLog(recent.flightLogNumber)">Delete Flog</button>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <p class="t-gray-3 text-center" *ngIf="recent.flights.length == 0">No flights in the flight log</p>

                    <mat-selection-list #list (selectionChange)="addFlightToDelete($event, flight)" *ngFor="let flight of recent.flights">
                        <mat-list-option class="mb-3" [checkboxPosition]="'before'" >
                            <div class="center-vertically" (click)="emitEditFlightEvent(recent, flight)">
                                <div class="flight-info">
                                    {{ flight.comercialAirlineCode + flight.flightNumber + ' ' + flight.flightDate }}
                                    <br>
                                    {{ flight.departure + '-' + flight.arrival + ' ' + flight.aircraftRegistrationNumber }}
                                </div>
                                <div class="d-flex align-items-center block-time">
                                    <div class="new-flight-block-time">
                                        <span>Block Time</span>
                                        <span>{{ flight.dates.blockTime | slice:0:5 }} hs</span>
                                    </div>
                                    <mat-icon matListIcon>keyboard_arrow_right</mat-icon>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>

                    <div class="btn-actions">
                        <button class="btn-outline btn-accept"  *ngIf="recent.flights.length > 0" (click)="deleteFlights()">Delete selected flights</button>
                        <button class="btn-flat btn-accept" (click)="emitAddFlightEvent(recent)">New Flight</button>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </div>

    <div class="title-main m-3">
        <span class="text-title">Old Flight Logs</span>
    </div>

    <div class="m-3">
        <div class="not-found" *ngIf="!flightLogs || flightLogs.old.length == 0">
            <p class="t-gray-3">{{!flightLogs ? 'Could not load your old flight logs, please try again later' : 'No flight logs'}}</p>
        </div>
        <mat-accordion>
            <ng-container *ngFor="let old of flightLogs?.old">
                <mat-expansion-panel class="old-flight-logs" collapsedHeight="64px" expandedHeight="6.2rem">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <img src="/assets/img/rol/flight_cycle.svg" class="mr-1" alt="">
                            <span class="mt-1">{{old.flightLogNumber}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <p class="t-gray-3 text-center" *ngIf="old.flights.length == 0">No flights in the flight log</p>

                    <mat-selection-list #list (selectionChange)="addFlightToDelete($event, flight)" *ngFor="let flight of old.flights">
                        <mat-list-option class="mb-3" [checkboxPosition]="'before'" >
                            <div class="center-vertically" (click)="old.allowEdit? emitEditFlightEvent(old, flight): null">
                                <div class="flight-info">
                                    {{ flight.comercialAirlineCode + flight.flightNumber + ' ' + flight.flightDate }}
                                    <br>
                                    {{ flight.departure + '-' + flight.arrival + ' ' + flight.aircraftRegistrationNumber }}
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="new-flight-block-time">
                                        <span>Block Time</span>
                                        <span>{{ flight.dates.blockTime | slice:0:5 }} hs</span>
                                    </div>
                                    <mat-icon matListIcon>keyboard_arrow_right</mat-icon>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                    <div class="btn-actions" *ngIf="old.allowEdit">
                        <button class="btn-action btn-mdb-color-pl" (click)="deleteFlights(true)">Delete selected flights</button>
                        <button class="btn-action btn-mdb-color-blue" (click)="emitAddFlightEvent(old)">New Flight</button>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </div>
</ng-container>
