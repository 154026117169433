import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DispatchService } from "src/app/modules/pilot/services/dispatch.service";

import jsPDF, { GState } from "jspdf";
import { SubcomponentState } from "src/app/modules/pilot/domain/dispatch/sub-component-state.model";
import { FlightId } from '../../../domain/flight-id.model';
import { ILoadSheetByFlight } from '../../../domain/loadsheet/loadsheet-by-flight.interface';
import { directos } from '../../../domain/dispatch/directos/directos';
import { directPilotComment } from '../../../domain/dispatch/directos/directPilotComment';
import { savePdf } from 'src/app/core/utils/utils';


import Swal from 'sweetalert2';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';


@Component({
    selector: 'directos-recommendation',
    templateUrl: './directos-recommendation.component.html',
    styleUrls: ['./directos-recommendation.component.css'],
    standalone: false
})
export class DirectosRecommendationComponent implements OnInit {

  public directsReport: directos;
  public usefulDirect: number = 0;
  comment: string = "";

  public savingComment: boolean = false;
  public initView:boolean= false;

  public feedback:boolean = false;

  @Input()
  public flight: FlightId;


  @Output() closeModalEvent = new EventEmitter();
  @Output() loadIsFinished = new EventEmitter<SubcomponentState>();

  @Output() reportFound = new EventEmitter<any>();

  public flightInfo: string = "";
  public flightInfoDate: string = "";


  constructor(
    private _dispatchService: DispatchService,
    private platform: Platform,
  ) { }


  ngOnInit(): void {

    let { localFlightDate, flightNumber, carrierOperating, departureIataCode, arrivalIataCode, flightDate, tail, dateOfFlight } = this.flight;
    let flightRequest: ILoadSheetByFlight = {
      flightDate: localFlightDate,
      operator: carrierOperating,
      flightNumber,
      departureIataCode,
      arrivalIataCode,
      tail,
      dateOfFlight,
      flightDateUtc: flightDate
    }
    this.flightInfo += `${carrierOperating + " " + flightNumber} ${departureIataCode}-${arrivalIataCode} ${tail}`;
    this.flightInfoDate += ` ${moment(flightDate).format('DD/MM/YYYY')}`;


    flightRequest.tail = flightRequest.tail.replace("-", "");
    this._dispatchService.getDirectos(flightRequest).subscribe({
      next: resp => {
        if (!resp || resp.length == 0) {
          this.loadIsFinished.emit(new SubcomponentState(this, 'not-available'));
          return;
        }
        this.directsReport = resp;
        this.loadIsFinished.emit(new SubcomponentState(this, 'success'));
        this.initView = true;
        this.feedback = (this.directsReport.usefulDirect!=0 && this.directsReport.comment!=null && this.directsReport.comment!="null") ? true :false;
        this.comment = this.feedback ? this.directsReport.comment : '';

      },
      error: err => {
        console.error('Error at DirectosRecommendationComponent ngOnInit: ', err);
        this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
      }
    });

  }

  closeModal() {
    this.usefulDirect = 0;
    this.closeModalEvent.emit();
  }

  saveComment(confirm: string, usefulDirect: number) {
    let pilotComment: directPilotComment = new directPilotComment;

    if (confirm == "withoutConfirm") {

      this.usefulDirect = usefulDirect;

      pilotComment.id = this.directsReport.id;
      pilotComment.usefulDirect = this.usefulDirect;

      this._dispatchService.saveDirectComment(pilotComment).subscribe({
        next: (resp) => {
        },
        error: (err) => {
          console.error('Error saving useful direct without confirm ', err);
        }
      });

    } else {

      this.savingComment = true;

      pilotComment.id = this.directsReport.id;
      pilotComment.usefulDirect = this.usefulDirect;
      pilotComment.comment = this.comment.length != 0 ? this.comment : null;


      this._dispatchService.saveDirectComment(pilotComment)
        .subscribe({
          next: resp => {
            this.savingComment = false;
            this.feedback=true;
            Swal.fire({
              icon: 'success',
              text: 'Thanks for your feedback!'
            });
          },
          error: error => {
            this.feedback=false;
            this.savingComment = false;
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'There was a problem saving your comment. Please try again.'
            });
          }
        });

    }

  }

  public generatePDF(): jsPDF {

    let pdf = new jsPDF();

    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = pdf.internal.pageSize.getHeight();


    let img = new Image()
    img.src = 'assets/img/navbar/logo-latam-pilot.jpg';
    pdf.addImage(img, 'png', pdfWidth - 40, 10, 30, 12);

    pdf.setFontSize(18);
    pdf.text('Direct Heading Recommender', pdfWidth / 2, 15, { align: 'center' });

    pdf.setFontSize(16);
    pdf.text('(Operational Recommendation)', pdfWidth / 2, 23, { align: 'center' });

    let finalY;
    finalY = 39;

    (pdf as any).autoTable({
      head: [[this.flightInfo, this.flightInfoDate]],
      headStyles: {
        fillColor: [27, 0, 136],
        lineColor: [217, 216, 216],
        valign: 'middle', halign: 'left', fontSize: 16
      },
      startY: 51, startX: -11,
      theme: 'striped',
      margin: { right: 11, left: 11 },
    });


    finalY = (pdf as any).lastAutoTable.finalY;

    (pdf as any).autoTable({
      head: [["Recommendation: "]],
      headStyles: {
        fillColor: [238, 237, 249],
        lineWidth: 0.06,

        valign: 'middle', halign: 'left', fontSize: 16
      },
      theme: 'plain',

      startY: finalY + 12, startX: -11,
      body: [[this.directsReport.recommendation]],
      bodyStyles: { fontSize: 18, textColor: [0, 0, 0], fillColor: [255, 255, 255], lineWidth: 0.06 },
      margin: { right: 11, left: 11 },
    });

    pdf.setGState(new GState({ opacity: 0.1 }));
    pdf.addImage(img, 'png', (pdfWidth / 2) - 50, (pdfHeight / 2) - 20, 100, 40);
    pdf.setGState(new GState({ opacity: 1 }));

    pdf.setFontSize(10);
    pdf.setTextColor('black')
    pdf.text("This document is generated exclusively by PILOT", pdfWidth / 2, pdfHeight - 10, { align: 'center' });

    return pdf;
  }

  getPDF(): string {
    let pdf = new jsPDF();
    pdf = this.generatePDF();

    return pdf.output('datauristring').split(',')[1];
  }

  downloadPDF() {
    let doc = new jsPDF();
    doc = this.generatePDF();
    let name = "Directo " + this.flightInfo + this.flightInfoDate;

    if (!this.platform.is('hybrid')) {
      // save doc web
      doc.save(name);
    } else {
      // save doc app mobile iOS | android
      let blob = doc.output("blob");
      let ref = this;
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        let base64 = reader.result.toString();
        ref.savePdfMobile(base64, name);
      }
    }
  }

  savePdfMobile(base64: string, name: string) {
    savePdf(base64, name);
  }

}