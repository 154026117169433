import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PilotConstants } from '../../commons/pilot-constants';
import Swal from 'sweetalert2';
import { ReportService } from '../../services/report.service';
import { ReportRequest } from '../../domain/reportRequest';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../commons/date.adapter';
import { IndexeddbService } from '../../services/indexeddb.service';
import { CommonsService } from '../../../../core/services/commons/commons.service';
import { UpperCasePipe } from '@angular/common';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { MatSelect } from '@angular/material/select';
import { Attachment } from 'src/app/modules/pilot/domain/attachment';
import { ModalController } from '@ionic/angular';
import { ReportsGuideModalComponent } from './reports-guide-modal/reports-guide-modal.component';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    providers: [
        UpperCasePipe,
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ],
    standalone: false
})

export class ReportsComponent implements OnInit {
  @ViewChild('picker',{static: false}) datePicker;

  @ViewChild('reasons') selectReasons: MatSelect;
  @ViewChild('subReason') selectSubReason: MatSelect;
  @ViewChild('freeText') selectFreeText;

  isConnected: boolean;
  flightReportStatus: ReportRequest;
  showLoading: boolean = false;
  reportRequest: ReportRequest = new ReportRequest();
  reportFormGroup: UntypedFormGroup;
  flagCheck: boolean;
  filteredDeparture: Observable<any>;
  filteredArrival: Observable<any>;
  fleetList = [];
  airportsList;
  errorValidForm: boolean = false;
  ownerList = this.constants.OWNER_LIST;
  reasonList = this.constants.REPORT_REASONS_LIST;
  reasonFormGroup = new FormControl<null>(null, Validators.required);
  kgExtraFuel = new UntypedFormControl(null);

  // field delay minutes
  hourControl = new UntypedFormControl("00" );
  minuteControl = new UntypedFormControl("00" );
  hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  delayMinutes: number = 0;

  neoreasonList: any;
  public subReasonList = [];
  subReason: {id: number, name: string};

  reason : { id: number, name: string, value: string, subReason:{id: number, name: string}[]};
  hasSubReasons: boolean = false;
  public savingSubReason: boolean = false;
  public reasonSelected:boolean = false;
  public subReasonSelected: boolean = false;
  public isExtraFuel: boolean = false;


  attachmentFiles : Array<Attachment> =  new Array<Attachment>();

  freeText = new UntypedFormControl('');
  file = new UntypedFormControl('');


  tailNumbers = this.constants.TAIL_NUMBERS;
  reportSubReasonsList = this.constants.REPORT_SUB_REASON;
  filteredOptions: Observable<string[]>;
  progressBar: number = 50
  showNav: boolean = true;
  bufferValue: number = 75;
  subReasonFormGroup;
  addedReasons = new Array();

  constructor(
    private router: Router,
    public constants: PilotConstants,
    private reportService: ReportService,
    private indexeddb: IndexeddbService,
    private formBuilder: UntypedFormBuilder,
    private commonsService: CommonsService,
    private pipe: UpperCasePipe,
    private route: ActivatedRoute,
    private _network: NetworkService,
    private _user: UserService,
    private _preferences: PreferencesService,
    private _security: SecurityService,
    private modalCtrl: ModalController
  ) {
    this.fleetList = this.constants.FLEET_LIST;
  }

  ngOnInit() {
    this.init();
    this.listenForm();
  }


  async init() {
    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(state => this.isConnected = state);
    }
    valideNetwork();

    this.airportsList = JSON.parse(await this._preferences.getItem("airportsList"));
    this.neoreasonList = JSON.parse(await this._preferences.getItem("reasonList"));

    this.instanciateForm();

    this.route.paramMap.subscribe((params: Params) => {
      const flightNumber = params.get('flightNumber');
      const operator = params.get('operator');
      let fleet = params.get('fleet');
      const tail = params.get('tail');
      const departure = params.get('departure');
      const arrival = params.get('arrival');
      const flightDate = params.get('flightDate');

      fleet = this.constants.FLEET_LIST_FAM.A320F.includes(fleet) ? this.constants.FLEET_LIST_FAM.A320F[0] : fleet;
      fleet = this.constants.FLEET_LIST_FAM.B787F.includes(fleet) ? this.constants.FLEET_LIST_FAM.B787F[0] : fleet;

      if(JSON.parse(flightNumber)) {
        this.reportFormGroup.patchValue({
          flightNumber,
          fleet,
          departure,
          arrival,
          flightDate: new Date(flightDate),
          owner: operator,
          tailNumber: tail
        });
        this.setRequest();
      }
    });


    this.loadFiltersAutocompleteArrival();
    this.loadFiltersAutocompleteDeparture();
    this.loadFleet();

    this.indexeddb.getDataFromTable('report', 'FlightReportStatus').then(value => {
      this.flightReportStatus = value;
      if (this.flightReportStatus) {
        this.setFlightReportStatus(this.flightReportStatus);
      }
    });
    this.indexeddb.getDataFromTable('report', 'requestFlightReport').then(fr => {
      if (fr) {
        Swal.fire({
          text: 'Your pending report will be sent as soon as the network is available.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: 'var(--blue-pilot)',
          confirmButtonText: 'Go to Home',
          heightAuto: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/home']);
          }
        })
      }
    });

    this.filteredOptions = this.reportFormGroup.controls.tailNumber.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  instanciateForm() {
    this.reportFormGroup = new UntypedFormGroup({
      owner: new UntypedFormControl('', [Validators.required]),
      flightNumber: new UntypedFormControl('', [Validators.pattern('[0-9]{1,4}[a-z]{0,1}')]),
      fleet: new UntypedFormControl(''),
      tailNumber: new UntypedFormControl(''),
      departure: new UntypedFormControl(''),
      arrival: new UntypedFormControl(''),
      flightDate: new UntypedFormControl(new Date(), [Validators.required]),
      cc: new UntypedFormControl(true)
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.tailNumbers.filter(option => option.toLowerCase().includes(filterValue));
  }

  deleteFile() {
    this.reportFormGroup.controls.fileName.setValue('');
    this.reportFormGroup.controls.file.setValue(null);
    this.saveReportStatus();
  }

  touchForm(formName) {
    for (let inner in formName.controls) {
      formName.get(inner).markAsTouched();
    }
  }

  sendReport() {
    if (this.reportFormGroup.valid && this.addedReasons.length > 0) {
      this.errorValidForm = false;
      this.setRequest();
      if (this.isConnected) {
        this.callFlightReport();
      } else {
        this.saveRequestFlightReport();
      }
    } else {
      this.errorValidForm = true;
    }
  }

  setRequest() {
    this.reportRequest = new ReportRequest();
    this.reportRequest.fleet = this.reportFormGroup.controls.fleet.value;
    this.reportRequest.flightDate = this.reportFormGroup.controls.flightDate.value;
    this.reportRequest.flightNumber = this.reportFormGroup.controls.flightNumber.value;
    this.reportRequest.owner = this.reportFormGroup.controls.owner.value;
    this.reportRequest.tailNumber = this.reportFormGroup.controls.tailNumber.value;
    this.reportRequest.cc = this.reportFormGroup.controls.cc.value;
    this.reportRequest.departure = this.pipe.transform(this.reportFormGroup.controls.departure.value);
    this.reportRequest.arrival = this.pipe.transform(this.reportFormGroup.controls.arrival.value);
    this.reportRequest.reasonList = this.addedReasons; //this.generateReasons();
  }
  
  deleteReason(i) {
    this.addedReasons.splice(i, 1);
    this.reportRequest.reasonList = this.addedReasons;
    this.indexeddb.addToTable("report", this.reportRequest, "FlightReportStatus");
  }

  getSubreasons(reason) {
    let subReasonArray = [];
    this.reportSubReasonsList.forEach(subReasonLabel => {
      if (reason.controls[subReasonLabel].value) {
        let inputValue = subReasonLabel.split(' ')
        let item = {
          name: subReasonLabel,
          delay: reason.controls[inputValue[0]].value
        }
        subReasonArray.push(item);
      }
    })
    return subReasonArray;
  }

  clickPicker() {
    this.datePicker.open();
  }

  getDataAndReportSubReason(reason) {
    this.reasonSelected = true;
    this.reason = this.neoreasonList.find(x => x.name == reason);
    this.subReasonList = this.reason.subReason;
    if(this.reason.value == "EXTRA_FUEL"){
      this.isExtraFuel = true;
    }else{
      this.isExtraFuel = false;
    }
  }

  getDataOfSubReason(reason) {
    this.subReasonSelected = true;
    this.subReason = this.subReasonList.find(x => x.name == reason);
  }


  async openModalGuide() {
    this.commonsService.registryEvent({ category: 'flight_report', event: 'open_guide_flight_report'});
      
    const modal = this.modalCtrl.create({
      component: ReportsGuideModalComponent
    });
    (await modal).present();
  }

  saveReason() {

   
    this.savingSubReason = true;

    let reasonToPush = {
      subReasonName: this.subReason.name,
      reasonName: this.reason.name,
      reasonValue: this.reason.value,
      delayValue: this.delayMinutes || 0,
      kgValue: Number(this.kgExtraFuel.value || 0),
      freeText: this.selectFreeText.nativeElement.value,
      attachmentFiles: this.attachmentFiles,
    };
    setTimeout(() => {
      this.addedReasons.push(reasonToPush);
      this.reportRequest.reasonList = this.addedReasons;
      this.indexeddb.addToTable("report", this.reportRequest, "FlightReportStatus");
      this.savingSubReason = false;
      this.resetValues();
    }, 1000);

  }

  resetValues(){
    this.subReasonSelected = false;
    this.reasonSelected = false;
    this.selectReasons.value = undefined;
    this.selectSubReason.value = undefined;
    this.selectFreeText.nativeElement.value = '';
    document.getElementById("fileNameInput").setAttribute("value","No file chosen");
    this.attachmentFiles = new Array<Attachment>();

    this.isExtraFuel = false;
    this.subReasonList = null;
    this.hourControl.reset("00");
    this.minuteControl.reset("00");
    this.kgExtraFuel.reset();
  }

  private listenForm() {
    this.file.valueChanges.subscribe(input => {
        let files = document.getElementById("file-input")["files"];
        this.addFiles(files);
    });

    this.hourControl.valueChanges.subscribe(input => this.getDelayMinutes());
    this.minuteControl.valueChanges.subscribe(input => this.getDelayMinutes());
  }

  private getDelayMinutes() {
    this.delayMinutes = 0;
    if(this.minuteControl.value && this.hourControl.value){
      this.delayMinutes = (Number(this.hourControl.value)*60) + Number(this.minuteControl.value);
    }
  }

  addFiles(files: File[]) {
    let count = 0;
    let listName: string = "";
    if (files?.length > 0) {
      for (let file of files) {
        const extension = file.name.split('.').pop().toLowerCase();
        const allowedExtensions = ['jpg', "jpeg", 'pdf', 'png'];
        if (!allowedExtensions.includes(extension)) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Invalid file. The allowed extensions are: ' + allowedExtensions.join(', ')
          });
          return;
        }
        if (file?.size >= 5000000) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Some of the attachments exceed the size limit.'
          });
          return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          this.attachmentFiles[count] = {
            fileName: "FILE_" + `${new Date().getTime()}.${extension}`,
            fileType: file.type,
            value: (<string>reader.result).split(',')[1]
          };
          listName = listName == "" ? file.name : listName + ', ' + file.name
          count++;
          let filelabel = document.getElementById('fileNameInput');
          filelabel.setAttribute('value', listName);
        } 
      }
    } 
  }

  getErrorMessage() {
    return this.kgExtraFuel.hasError('required') ? 'You must enter a value' : 'You must enter a numeric value';
  }

  deleteAttachment() {
    let filelabel = document.getElementById('fileNameInput');
    filelabel.setAttribute("value","No file chosen");
    this.attachmentFiles = new Array<Attachment>();
  } 

  callFlightReport() {
    this.showLoading = true;
    this._security.setLoading(true);
    this.reportService.postFlightReport(this.reportRequest)
    .pipe(finalize(() => {
      this.showLoading = false;
      this._security.setLoading(false);
    }))
    .subscribe({
      next: resp => { 
        this.commonsService.registryEvent({ category: 'flight_report', event: 'create_flight_report'});
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your report has been sent',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.indexeddb.deleteElementFromDatabase('report', 'FlightReportStatus');
            this.router.navigate(['/home']);
          }
        });
      },
      error: err => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ooops! There was a problem. Please try again.'
        });
      },
    });
  }

  saveRequestFlightReport() {
    this.indexeddb.addToTable("report", this.reportRequest, "requestFlightReport");
    Swal.fire({
      icon: 'success',
      title: 'Thank you',
      text: 'Your report will be sent as soon as the network is available',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.reportService._pendingReport.next(true);
        this.router.navigate(['/home']);
      }
    });
  }

  loadFleet() {
    let info = this._user.getUserValue;
    let { operator, fleet } = info.profile;
    this.reportFormGroup.controls.owner.setValue(operator);
    this.reportFormGroup.controls.fleet.setValue(fleet);
  }

  saveReportStatus() {
    this.setRequest();
    this.indexeddb.addToTable("report", this.reportRequest, "FlightReportStatus");
  }

  setFlightReportStatus(reportStatus) {
    this.reportFormGroup.controls.owner.setValue(reportStatus.owner);
    this.reportFormGroup.controls.flightNumber.setValue(reportStatus.flightNumber);
    this.reportFormGroup.controls.fleet.setValue(reportStatus.fleet);
    this.reportFormGroup.controls.tailNumber.setValue(reportStatus.tailNumber);
    this.reportFormGroup.controls.flightDate.setValue(reportStatus.flightDate);
    this.reportFormGroup.controls.cc.setValue(reportStatus.cc);
    this.reportFormGroup.controls.departure.setValue(this.pipe.transform(reportStatus.departure));
    this.reportFormGroup.controls.arrival.setValue(this.pipe.transform(reportStatus.arrival));
    this.addedReasons = reportStatus.reasonList;
    this.setRequest();
  }

  cancelFR() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You will delete your progress",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--blue-pilot)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.indexeddb.deleteElementFromDatabase('report', 'FlightReportStatus');
        this.instanciateForm();
        this.loadFleet();
        this.addedReasons = [];
        Swal.fire(
          'Deleted!',
          'All the fields has been deleted',
          'success'
        )
      }
    })
  }

  checkValue(elementValue, subreasonName, formArraiId, formGroupId) {
    if (!elementValue) {
      let itemname = subreasonName.split(' ');
      let delayActualValue = this.reportFormGroup.controls['reasonListFormArray']['controls'][formArraiId]['controls'].delay.value
      let delayATCValue = this.reportFormGroup.controls['reasonListFormArray']['controls'][formArraiId]['controls'].subReasons.controls[formGroupId]['controls'][itemname[0]].value
      if (delayActualValue != 0 && delayATCValue != 0 && delayATCValue != '') {
        let newValue = delayActualValue - delayATCValue;
        this.reportFormGroup.controls['reasonListFormArray']['controls'][formArraiId]['controls'].delay.setValue((newValue == 0 ? '' : newValue));
        this.reportFormGroup.controls['reasonListFormArray']['controls'][formArraiId]['controls'].subReasons.controls[formGroupId]['controls'][itemname[0]].setValue('')
      }
    }
  }

  setValue(formArraiId, formGroupId) {
    let formGroup = this.reportFormGroup.controls['reasonListFormArray']['controls'][formArraiId]['controls'].subReasonList.controls[formGroupId];
    let sum = 0
    this.reportSubReasonsList.forEach(subreasonName => {
      if (formGroup.controls[subreasonName].value) {
        let itemname = subreasonName.split(' ');
        sum = sum + parseInt((formGroup.controls[itemname[0]].value == '' || formGroup.controls[itemname[0]].value == null ? 0 : formGroup.controls[itemname[0]].value));
      }
    })
    this.reportFormGroup.controls['reasonListFormArray']['controls'][formArraiId]['controls'].delay.setValue(sum.toString());
  }

  addReason() {
    let subReason = new UntypedFormGroup({})

    this.reportSubReasonsList.forEach(item => {
      let check = new UntypedFormControl(false);
      let inputname = item.split(' ');
      let input = new UntypedFormControl();
      subReason.addControl(item, check);
      subReason.addControl(inputname[0], input);
    })

    let reason = this.formBuilder.group({
      name: new UntypedFormControl(),
      delay: new UntypedFormControl(),
      freeText: new UntypedFormControl(),
      subReasonList: this.formBuilder.array([subReason])
    })

    this.reportFormGroup.controls['reasonListFormArray']['controls'].push(reason)
  }

  removeReason(index) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--blue-pilot)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let reason = <UntypedFormArray>this.reportFormGroup.controls['reasonListFormArray'];
        reason.removeAt(index)
      }
    })
  }

  loadFiltersAutocompleteDeparture() {
    this.filteredDeparture = this.reportFormGroup.controls.departure.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAirport(value))
      );
  }

  loadFiltersAutocompleteArrival() {
    this.filteredArrival = this.reportFormGroup.controls.arrival.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAirport(value))
      );
  }

  private _filterAirport(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.airportsList.filter(option => option.toLowerCase().includes(filterValue));
  }
}
