import { Component, Input } from '@angular/core';
import { FlightLogInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-info.model';

@Component({
    selector: 'roster-fcard-sm',
    templateUrl: './roster-fcard-sm.component.html',
    styleUrls: ['./roster-fcard-sm.component.css', '../rol.component.css', '../roster-fcard/roster-fcard.component.css'],
    standalone: false
})
export class RosterFcardSm {

  @Input('flight') activity: FlightLogInfo;
  innerWidth = window.innerWidth;

}