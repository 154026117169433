<ion-app>

    <div *ngIf="!this.isConnected" class="no-connection-message">
        {{ constants.MESSAGES_APP.GENERAL.NO_CONNECTION }}
    </div>

    <!--Loading-->
    <app-loading *ngIf="showLoading"></app-loading>
    <!--Loading-->

    <mat-sidenav-container [ngClass]="{'extra-top': !this.isConnected}">

        <mat-sidenav #sidenav [mode]="sidenavtype" class="side-nav-open">
            <div>
                <img [ngClass]="innerWidth > 420 ? 'background-header' : 'mini-background-header'"
                    src="assets/img/home/bg-img-sidenav.jpg" alt="background header">
                <img class="logo-header" src="assets/img/isotipo/logo-pilot.png" alt="logo pilot header">
                <a class="float-right" (click)="sidenav.toggle()">
                    <div class="content-close">
                        <i class="material-icons">chevron_left</i>
                    </div>
                </a>
            </div>

            <app-side-nav *ngIf="nav && user" [locationNav]="'home'" [statusSendReport]="statusSendReport"
                [user]="user"></app-side-nav>
        </mat-sidenav>

        <ion-content>

            <ion-refresher slot="fixed" pullFactor="0.5" (ionRefresh)="handleRefresh($event)" [disabled]="disableRefresh">
                <ion-refresher-content slot="fixed" pullingIcon="arrow-down" pullingText="Pull to refresh"
                    refreshingSpinner="lines-sharp" refreshingText="Refreshing..." class="white-icon">

                    <div *ngIf="showSpinner" class="content-spinner">
                        <img width="30" height="30" src="assets/img/loading/airplane.gif" class="airplane-img" alt="">
                    </div>

                </ion-refresher-content>
            </ion-refresher>

            <!--Main Navigation-->
            <app-navbar *ngIf="nav && user" (toggleSidenav)="openSidebar($event)" [locationNav]="'home'" [route]="route"
                [user]="user"></app-navbar>
            <!--Main Navigation-->

            <router-outlet></router-outlet>
            <!-- +Space -->
            <div *ngIf="!currentURL.includes('login') && !currentURL.includes('on-boarding')" class="extra-space"></div>
            <!-- +Space -->
            <!-- Inferior tabs -->
            <div *ngIf="nav && user &&  !currentURL.includes('tools')" class="secondary-menu-home">
                <ul>
                    <li *ngIf="!hideSection" routerLink="/safety" [ngClass]="componentShow=='safety'? 'active' : ''"
                        (click)="showComponent('safety')">
                        <img class="home-icon"
                            [src]="componentShow=='safety' ? 'assets/img/home/new-home/safety-active.svg' : '/assets/img/home/new-home/safety.svg'"
                            alt="">
                        <p class="small">Safety</p>
                    </li>
                    <li *ngIf="!hideSection" routerLink="/fuel" [ngClass]="componentShow=='fuel'? 'active' : ''"
                        (click)="showComponent('fuel')">
                        <img class="home-icon"
                            [src]="componentShow=='fuel' ? 'assets/img/home/new-home/efficiency-active.svg' : '/assets/img/home/new-home/efficiency.svg'"
                            alt="">
                        <p class="small">Efficiency</p>
                    </li>
                    <li routerLink="/home" [ngClass]="componentShow=='home'? 'active' : ''"
                        (click)="showComponent('home')">
                        <img class="home-icon w-42"
                            [src]="componentShow=='home' ? 'assets/img/home/new-home/home-active.svg' : '/assets/img/home/new-home/home.svg'"
                            alt="">
                        <p class="small">Home</p>
                    </li>
                    <li *ngIf="!hideSection" [routerLink]="['/roster']" [queryParams]="{section: 'all'}"
                        [ngClass]="componentShow=='roster'? 'active' : ''" (click)="showComponent('roster')">
                        <img class="home-icon"
                            [src]="componentShow=='roster' ? 'assets/img/home/new-home/roster-active.svg' : '/assets/img/home/new-home/roster.svg'"
                            alt="">
                        <p class="small">Roster</p>
                    </li>
                    <li *ngIf="!hideSection" [routerLink]="['/roster']" [queryParams]="{section: 'briefing'}"
                        [ngClass]="componentShow=='briefing'? 'active' : ''" (click)="showComponent('briefing')">
                        <img class="home-icon w-42"
                            [src]="componentShow=='briefing' ? 'assets/img/home/new-home/briefing-active.svg' : '/assets/img/home/new-home/briefing.svg'"
                            alt="">
                        <p class="small">Briefing</p>
                    </li>
                </ul>
            </div>
            <!-- Inferior tabs -->
        </ion-content>
        
    </mat-sidenav-container>

</ion-app>