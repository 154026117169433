import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToBack',
    standalone: false
})
export class DateToBackPipe implements PipeTransform {

  transform(date: Date, plus:boolean, monthCalculate:number, dayCalculate?: number ): any {
    let day;
    if(plus){
      if (dayCalculate > -1) {
        day = this.calculateDayForYear(date, dayCalculate);
      } else {
        day = date.getDate()
      }
      let month = (date.getMonth() +1) ;
      let newDate = date.getFullYear() + "-" + (month < 10 ? "0"+month : month) + "-" + day;      
      return newDate;
    }else {
      
      const item = new Date(date);
      item.setMonth((item.getMonth() - monthCalculate));
      if (dayCalculate > -1) {
        day = this.calculateDayForYear(item, dayCalculate);
      } else {
        day = date.getDate()
      }
      let month = (item.getMonth() +1);
      let newDate = item.getFullYear() + "-" + (month < 10 ? "0"+ month : month) + "-" + day;
      return newDate;
    }    
  }

  calculateDayForYear (date, dayCalculate) {
    if(dayCalculate == 0) {
      return new Date(date.getFullYear(), date.getMonth() + 1, dayCalculate).getDate();
    } else {
      return new Date(date.getFullYear(), date.getMonth(), dayCalculate).getDate();
    }
  }
}
