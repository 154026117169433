import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonsService } from '../commons/commons.service';
import Swal from 'sweetalert2'
import { IndexeddbService } from '../../../modules/pilot/services/indexeddb.service';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { PreferencesService } from '../preferences/preferences.service';
import { Device } from '@capacitor/device';
import { IProfile, IUser } from '../../../modules/pilot/domain/user/user';
import { RemoteConfigService } from '../../../modules/pilot/services/remote-config/remote-config.service';
import { PilotConstants } from '../../../modules/pilot/commons/pilot-constants';
import { ProfileService } from '../../../modules/pilot/services/profile.service';
import { QRCode } from '../../../modules/pilot/domain/login/qrCode';
import { DeviceService } from '../../../modules/pilot/services/device/device.service';
import { DeviceInfoRequest } from '../../../modules/pilot/domain/profile/device-info.model';
import { GoogleAuthResponseWeb } from 'src/app/modules/pilot/domain/user/auth.interface';
import { App } from '@capacitor/app';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  
  profile: IProfile;
  public auth2: any;
  _showLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showLoading: Observable<boolean> = this._showLoading.asObservable();

  constructor(
    private indexeddbService: IndexeddbService, 
    private router: Router, 
    private cookieService: CookieService, 
    private commonsService: CommonsService,
    private pilotProfileService: ProfileService,
    private _user: UserService,
    private _preferences: PreferencesService,
    private ngZone: NgZone,
    private remoteConfig: RemoteConfigService,
    private contants: PilotConstants,
    private _device: DeviceService,
    private indexeddb: IndexeddbService,
    private constants: PilotConstants,
  ) {}

  setLoading(state:boolean) {
    this._showLoading.next(state);
  }

  async getBP(mail: string, user: IUser){
    this.commonsService.getPilot().subscribe({
      next: (resp: string[]) => {
        this._user.updateLocalUser({ ...user, roles: resp});
        this.getVersionApp();
        this.commonsService.registryEvent({ category: 'login', event: "success_login"});
      },
      error: err => {
        this.commonsService.registryEvent({ category: 'login', event: "without_permission"});
        this.setLoading(false);
        Swal.fire({
          text: 'You do not have permission to access to this application',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          heightAuto: false,
          allowOutsideClick: false,
          footer: `<a class='t-gray-3' href=${this.contants.HOW_CREATE_AN_ACCOUNT} target='_blank' >Click here to request access</a>`
        }).then((result) => {
          this.clearSession();
        });
      }
    });
  }

  getName(profile: any, platform: "ios" | "android" | "web") {
    let name = "";
    if (platform == "web") {
      name = profile.getName();
    } else {
      name = platform == "android"? profile.displayName : profile.name;
    }
    return name.split(" ")[0];
  }

  async setUserLogin(profile: any, idToken: string, accessToken: string) {
    let { platform } = await Device.getInfo();
    this.setLoading(true);
    let userName = this.getName(profile, platform);
    let user = {
      userName, 
      userID: platform == "web"? profile.getEmail(): profile.email, 
      lastName: platform == "web"? profile.getFamilyName(): profile.familyName,
      profilePicture: platform == "web"? profile.getImageUrl(): profile.imageUrl,
      idToken,
      accessToken,
      refreshToken: null,
      roles: []
    }
    this._user.updateLocalUser(user);
    this.getBP(user.userID, user);
  }

  async finishSession() {
    return new Promise<boolean>((resolve, reject) => {
      this.commonsService.finishSession().subscribe({
        next: (resp: string) => {
          console.log("finishSession", resp);
          resolve(true);
        },
        error: err => {
          console.error("error finishSession", err);
          resolve(true);  
        }
      });
    })
  }

  async logOut() {
    if (window.location.pathname == "/login") {
      return;
    }
    let version = await this._preferences.getItem('version');
    const info = await Device.getInfo();
    let isWeb = info.platform == "web";
    this.setLoading(true);
    let isAppIpads = false;
    let value = await this._preferences.getItem("login_qr");
    let isLoginQR = JSON.parse(value);
    if (!isWeb) {
      let app = await App.getInfo();
      isAppIpads = app.id == this.constants.IDS_IOS.ID_APP_STORE_ENTERPRISE;
    }
    if (!isAppIpads && !isLoginQR) await this.finishSession();
    
    if (!isWeb) {
      GoogleAuth.signOut().then(async () => {
        let info = await this._device.getDeviceInfo();
        let infoRQ: DeviceInfoRequest = { ...info, isActiveSession: false };
        await this._device.validateDeviceInfo(infoRQ);
        this._user.updateLocalUser(null);
        this._preferences.clearStorage();
        this.cookieService.deleteAll();
        this.indexeddbService.clearDatabase();
        this.setLoading(false);
        this.router.navigate(['login']);
      });
    } else {
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init(this.contants.GOOGLE_AUTH_WEB_CONFIG).then(()=> {
          this.auth2 = gapi.auth2.getAuthInstance();
          this.auth2.signOut();
          this.auth2.disconnect();
          this._preferences.clearStorage();
          this.cookieService.deleteAll();
          this.indexeddbService.clearDatabase();
          if (version) this._preferences.setItem('version', version);
          if (window.location.pathname != "/login") {
            this.ngZone.run(() => {
              this.router.navigate(["login"]);
            });
          }
          this.setLoading(false);
        })
      });
    }
  }

  async refreshToken(): Promise<string> {
    console.log("Refresh");
    let info = await Device.getInfo();
    return new Promise((resolve, reject) => {
      if (info.platform == "web") {
        gapi.load('auth2', () => {
          this.auth2 = gapi.auth2.init(this.contants.GOOGLE_AUTH_WEB_CONFIG)
            .then(() => {
              this.auth2 = gapi.auth2.getAuthInstance();
              if (this.auth2.currentUser.get().getBasicProfile() != undefined) {
                this.auth2.currentUser.get().reloadAuthResponse();
                let authResponse: GoogleAuthResponseWeb = this.auth2.currentUser.get().getAuthResponse(true);
                this.updateUserToken(authResponse.id_token, authResponse.access_token);
                resolve(authResponse.id_token);
              }
            });
        });
      } else {
        GoogleAuth.refresh().then((data) => {
          let id_token = data.idToken;
          let access_token = data.accessToken;
          let refreshToken = data.refreshToken;
          this.updateUserToken(id_token, access_token, refreshToken);
          resolve(id_token);
        }).catch( async (error) => {
          console.error("GoogleAuth refresh error: ", error);
          const googleUser: User = await GoogleAuth.signIn();
          let id_token = googleUser.authentication.idToken;
          let access_token = googleUser.authentication.accessToken;
          this.updateUserToken(id_token, access_token);
          resolve(id_token);
        });
      }
    })
  }
  
  async updateUserToken(idToken: string, accessToken?: string, refreshToken? : string) {
    let user = this._user.getUserValue;
    this._user.updateLocalUser({ ...user, idToken, accessToken, refreshToken });
  }

  async getProfilePilot() {
    let user = this._user.getUserValue;
    const info = await Device.getInfo();
    let isWeb = info.platform == "web";
    this.pilotProfileService.getProfilePilot().subscribe({
      next: resp => {
        this.profile = resp;
        this._user.updateLocalUser({...user, profile: resp });
        let toRoute = user.roles.includes('PILOT')? "/home": "/tools";
        let route = this.profile.onBoarding || isWeb? toRoute: "/on-boarding";
        this.setLoading(false);
        this.ngZone.run(() => this.router.navigate([route]));
      },
      error: error => {
        console.log(error);
        this.setLoading(false);
        Swal.fire({
          title: 'Error',
          text: 'Oooops! There was a problem in login. Please try again',
          icon: 'error'
        });
        this.clearSession();
      }
    });
  }

  async getVersionApp() {
    let version = await this._preferences.getItem('version');
    this.remoteConfig.getParameterRemoteConfig('version').subscribe({
      next: resp => {
        if(!version) this._preferences.setItem('version', resp);
        this.getProfilePilot();
      },
      error: error => {
        console.error("error getVersionApp", error);
        this.getProfilePilot();
      }
    });
  }

  async getSessionUser(code: QRCode) {
    this.commonsService.getSession(code).subscribe({
      next: (user: IUser) => {
        console.log("user", user);
        if (user) {
          this.setLoading(true);
          this._user.updateLocalUser(user);
          this.getBP(user.userID, user);
        } else {
          Swal.fire({
            title: 'Info',
            text: 'You do not have permission to access with QR Code',
            icon: 'info'
          });
        }
      },
      error: err => {
        console.error("Error login", err);
        Swal.fire({
          title: 'Error',
          text: 'Oooops! There was a problem in login with QR. Please try again',
          icon: 'error'
        });
        this.clearSession();
      }
    });
  }

  clearSession() {
    this._user.updateLocalUser(null);
    this._preferences.clearStorage();
    this.cookieService.deleteAll();
    this.indexeddbService.clearDatabase();
    this.router.navigate(["login"]);
  }

  // Validar permisos Pilot Tools
  isAllowed(rolesToCheck: string[]): boolean {
    let roles: string[] = this._user.getUserValue.roles;
    return rolesToCheck.some( roletc => roles.some(role => role === roletc));
  }

}
