import { Component, Input } from '@angular/core';


import { MaintenixReport } from 'src/app/modules/pilot/domain/dispatch/maintenix/MaintenixReport';

@Component({
    selector: 'maintenix-report-section',
    templateUrl: './maintenix-report-section.component.html',
    styleUrls: ['./maintenix-report-section.component.css'],
    standalone: false
})
export class MaintenixReportSection{
  
    @Input()
    public report: MaintenixReport;

  }