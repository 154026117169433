
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import jsPDF, { GState } from 'jspdf';
import 'jspdf-autotable';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { GroundSupportStatus } from 'src/app/modules/pilot/domain/dispatch/ground-support-status.model';
import { SubcomponentState } from 'src/app/modules/pilot/domain/dispatch/sub-component-state.model';
import { DispatchService } from 'src/app/modules/pilot/services/dispatch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'ground-support-status',
  templateUrl: './ground-support-status.component.html',
  styleUrls: ['./ground-support-status.component.css', '../dispatch.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ transform: 'translateX(100%)' }),
          style({ transform: 'translateX(0%)' }),
        ])),
        transition(':leave', [
          animate('0.5s', keyframes([
            style({ transform: 'translateX(0%)' }),
            style({ transform: 'translateX(100%)' })
          ]))
        ])
      ])
    ])
  ],
  standalone: false
})
export class GroundSupportStatusComponent implements OnInit {

  @Input() arrival: string;
  @Input() fleet: string;
  @Input() departure: string;

  @Output() closeModalEvent = new EventEmitter();
  @Output() loadIsFinished = new EventEmitter<SubcomponentState>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ['base', 'fleet', 'acu', 'asulpu', 'gpu', 'lastUpdate'];

  public dataSource: any;
  private dataForExport: any;

  private groundSupportStatusData: Array<GroundSupportStatus>;
  private groundSupportFilteredData: Array<GroundSupportStatus>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  baseCtrl = new FormControl('');
  fleetCtrl = new FormControl('');
  filteredBases: Observable<string[]>;
  filteredFleets: Observable<string[]>;
  bases: string[] = [];
  fleetList: string[] = [];
  allBases: string[] = [];
  allFleets: string[] = ["319", "320", "321", "767", "777", "787", "789"];
  fleetNB: string[] = ["319", "320", "321"];
  fleetWB: string[] = ["767", "777", "787", "789"];



  @ViewChild('baseInput') baseInput: ElementRef<HTMLInputElement>;
  @ViewChild('fleetInput') fleetInput: ElementRef<HTMLInputElement>;


  constructor(private dispatchService: DispatchService) {
    this.filteredBases = this.baseCtrl.valueChanges.pipe(
      startWith(null),
      map((base: string | null) => (base ? this.filterBase(base) : this.allBases.slice())),
    );

    this.filteredFleets = this.fleetCtrl.valueChanges.pipe(
      startWith(null),
      map((fleet: string | null) => (fleet ? this.filterFleet(fleet) : this.allFleets.slice())),
    );
  }

  private filterBase(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allBases.filter(base => base.toLowerCase().includes(filterValue));
  }

  private filterFleet(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allFleets.filter(fleet => fleet.toLowerCase().includes(filterValue));
  }

  public addBase(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our base
    if (value) {
      this.bases.push(value);
    }

    // Clear the input value
    event!.value = "";

    this.baseCtrl.setValue(null);

    this.sortData();
  }

  public addFleet(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.fleetList.push(value);
    }
    event!.value = "";
    this.fleetCtrl.setValue(null);
    this.sortData();
  }

  public removeBase(base: string): void {
    const index = this.bases.indexOf(base);

    if (index >= 0) {
      this.bases.splice(index, 1);
    }

    this.sortData();
  }

  public baseIsSelected(event: MatAutocompleteSelectedEvent): void {
    this.bases.push(event.option.viewValue);
    this.baseInput.nativeElement.value = '';
    this.baseCtrl.setValue(null);

    this.sortData();
  }

  public fleetIsSelected(event: MatAutocompleteSelectedEvent): void {
    this.fleetList.push(event.option.viewValue);
    this.fleetInput.nativeElement.value = '';
    this.fleetCtrl.setValue(null);

    this.sortData();
  }

  public removeFleet(fleet: string): void {
    const index = this.fleetList.indexOf(fleet);

    if (index >= 0) {
      this.fleetList.splice(index, 1);
    }

    this.sortData();
  }

  ngOnInit(): void {
    this.bases = [this.departure, this.arrival];
    this.fleetList = [this.fleet];

    this.dispatchService.getGroundSupportStatusReport().subscribe({
      next: resp => {
        if (!resp || resp.length == 0) {
          this.loadIsFinished.emit(new SubcomponentState(this, 'not-available'));
          return;
        }
        this.groundSupportStatusData = resp;
        this.filterForFleet();
        this.fillTable(this.groundSupportFilteredData, true);
        this.loadIsFinished.emit(new SubcomponentState(this, 'success'));

        this.allBases = this.groundSupportStatusData.reduce((unique, o) => {
          if (!unique.some(obj => obj.base === o.base)) {
            unique.push(o);
          }
          return unique;
        }, []).map(gss => gss.base);
        this.filterTable();

      },
      error: err => {
        console.error('Error at RouteReportComponent ngOnInit: ', err);
        this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
      }
    });
  }

  fillTable(data: Array<GroundSupportStatus>, prepareDataToExport: boolean = false) {

    if (prepareDataToExport) {
      this.dataForExport = data.map(gss => [gss.base, gss.fleet, gss.acu, gss.asulpu, gss.gpu, gss.lastUpdate]);
    }

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  getPDF(): string {

    let firstPageLimit = 32;
    let dataLimitForPage = 35;

    let length = this.dataForExport.length;

    let tableFiltered: Array<GroundSupportStatus> = [];

    let firstTable: Array<GroundSupportStatus> = [];
    let secondTable: Array<GroundSupportStatus> = [];

    for (let index = 0; index < length; index++) {
      if (this.dataForExport[index][2].toLowerCase() == "available" || this.dataForExport[index][3].toLowerCase() == "available" || this.dataForExport[index][4].toLowerCase() == "available") {
        tableFiltered.push(this.dataForExport[index]);
      }
    }

    let doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

    let img = new Image()
    img.src = 'assets/img/navbar/logo-latam-pilot.jpg';
    doc.addImage(img, 'png', pageWidth - 40, 10, 30, 12);

    doc.setFontSize(18);

    //tittle section
    doc.text('Ground Equipment Support', pageWidth / 2, 20, { align: 'center' });
    doc.setFontSize(10);
    let stringGenerateDate: string = "Document creation date: " + moment.utc().format('DD/MMM/YY HH:mm') + "(UTC)";

    doc.text(stringGenerateDate, 11, 35);
    doc.setTextColor('green');
    doc.text("Available", pageWidth - 53, 35);
    doc.setTextColor('red');
    doc.text("Not Available", pageWidth - 35, 35);
    doc.setTextColor(100);

    let tableStart = 40;

    let filteredLength = tableFiltered.length;
    let numberOfFullDataPages = (filteredLength - (firstPageLimit * 2)) / (dataLimitForPage * 2);

    for (let i = 0; i <= Math.ceil(numberOfFullDataPages); i++) {
      if (i == 0) {
        firstTable = tableFiltered.slice(0, (firstPageLimit));
        tableFiltered.splice(0, (firstPageLimit));
        secondTable = tableFiltered.slice(0, (firstPageLimit));
        tableFiltered.splice(0, (firstPageLimit));

        this.tableForDocs(firstTable, secondTable, i, tableStart, doc, false);

      }
      else {
        firstTable = tableFiltered.slice(0, (dataLimitForPage));
        tableFiltered.splice(0, (dataLimitForPage));
        secondTable = tableFiltered.slice(0, (dataLimitForPage));
        tableFiltered.splice(0, (dataLimitForPage));

        this.tableForDocs(firstTable, secondTable, i, (doc as any).lastAutoTable.settings.margin.top, doc, true);
      }
    }

    //line between tables
    let numberOfPages = doc.getNumberOfPages();
    for (let index = 1; index <= numberOfPages; index++) {
      doc.setPage(index);

      doc.setTextColor('black');
      doc.text("This document is generated exclusively by PILOT", pageWidth / 2, pageHeight - 10, { align: 'center' });
      doc.setGState(new GState({ opacity: 0.1 }));
      doc.addImage(img, 'png', (pageWidth / 2) - 50, (pageHeight / 2) - 20, 100, 40);
      doc.setGState(new GState({ opacity: 1 }));


      if (index == 1) {
        doc.line(pageWidth / 2, tableStart, pageWidth / 2, pageHeight - (doc as any).lastAutoTable.settings.margin.top - 8);
      } else if (index == numberOfPages) {
        doc.line(pageWidth / 2, (doc as any).lastAutoTable.settings.margin.top, pageWidth / 2, (doc as any).lastAutoTable.finalY);
      } else {
        doc.line(pageWidth / 2, (doc as any).lastAutoTable.settings.margin.top, pageWidth / 2, pageHeight - (doc as any).lastAutoTable.settings.margin.top - 8);
      }
    }

    return doc.output('datauristring').split(',')[1];
  }


  private tableForDocs(firstTable: Array<GroundSupportStatus>, secondTable: Array<GroundSupportStatus>, numberOfPage: number, tableStart: number, doc: jsPDF, newPage: boolean) {

    if (newPage) doc.addPage();
    doc.setPage(numberOfPage + 1);


    (doc as any).autoTable({
      head: [['Base', 'Fleet', 'ACU', 'ASI/LPU', 'GPU', 'Last Updated']],
      headStyles: { fontSize: 9, fillColor: [27, 0, 136] },
      startY: tableStart, startX: -11,
      body: firstTable,
      theme: 'striped',
      bodyStyles: { fontSize: 9 },
      margin: { right: 109, left: 11 },
      didParseCell: function (data) {

        data.cell.styles.halign = 'center';

        if (data.section == 'body' && data.column.dataKey < 5 && data.column.dataKey > 1) {
          data.cell.styles.font = 'ZapfDingbats';
          data.cell.text = "l";
          data.cell.styles.textColor = data.cell.raw.toLowerCase() == "available" ? "green" : "red";
        }

        if (data.column.dataKey == 5 && data.section == 'body') {
          let datee = moment(data.cell.raw).format('DD/MMM/YY HH:mm');
          data.cell.text = datee.toString();
        }
      }
    });

    if (secondTable.length != 0) {
      this.setSecondTable(doc, numberOfPage, tableStart, secondTable);
    }
  }

  setSecondTable(doc, numberOfPage, tableStart, secondTable) {
    doc.setPage(numberOfPage + 1);

    (doc as any).autoTable({
      head: [['Base', 'Fleet', 'ACU', 'ASI/LPU', 'GPU', 'Last Updated']],
      headStyles: { fontSize: 9, fillColor: [27, 0, 136] },
      startY: tableStart,
      body: secondTable,
      theme: 'striped',
      bodyStyles: { fontSize: 9 },
      margin: { left: 109 },
      didParseCell: function (data) {

        data.cell.styles.halign = 'center';

        if (data.section == 'body' && data.column.dataKey < 5 && data.column.dataKey > 1) {
          data.cell.styles.font = 'ZapfDingbats';
          data.cell.text = "l";
          data.cell.styles.textColor = data.cell.raw.toLowerCase() == "available" ? "green" : "red";
        }

        if (data.column.dataKey == 5 && data.section == 'body') {
          let datee = moment(data.cell.raw).format('DD/MMM/YY HH:mm');
          data.cell.text = datee.toString();
        }
      }

    });
  }

  private filterForFleet() {

    let family = this.fleetNB.includes(this.fleet) ? this.fleetNB : this.fleetWB;

    let filteredByFleet: GroundSupportStatus[] = new Array();
    this.groundSupportStatusData.forEach(groundSupport => {

      if (family.includes(groundSupport.fleet)) {
        filteredByFleet.push(groundSupport);
      }
    });



    //orden alfabetico
    filteredByFleet.sort((a, b) => a.base > b.base ? 1 : -1);

    this.filteredByFleet(filteredByFleet);

  }

  private filteredByFleet(filteredByFleet: GroundSupportStatus[]) {
    let departure = this.departure;
    let arrival = this.arrival;
    let fleet = this.fleet;
    //departure y arrival al inicio de listado
    filteredByFleet.sort(function (x, y) {
      return ((x.base == arrival && x.fleet == fleet) || (x.base == departure && x.fleet == fleet)) ? -1 : ((y.base == arrival && y.fleet == fleet) || (y.base == departure && y.fleet == fleet)) ? 1 : 0;
    });

    this.fillTable(filteredByFleet, true);

    this.groundSupportFilteredData = filteredByFleet;
  }

  private filterTable() {
    this.fillTable(this.groundSupportFilteredData);

    this.dataSource.filterPredicate = (data: GroundSupportStatus, filter: Array<string>) => {
      return (this.bases.length > 0 ? filter.includes(data.base.toUpperCase()) : true) && (this.fleetList.length > 0 ? filter.includes(data.fleet) : true);
    };

    this.dataSource.filter = this.bases.concat(this.fleetList);
  }

  private sortData() {
    const selectedBases = this.bases;
    const selectedFleets = this.fleetList;

    this.groundSupportFilteredData.sort((a, b) =>
      selectedBases.indexOf(a.base) - selectedBases.indexOf(b.base)
    );
    this.groundSupportFilteredData.sort(function (a, b) {
      return selectedFleets.indexOf(a.fleet) - selectedFleets.indexOf(b.fleet);
    });

    this.filterTable();

  }

}