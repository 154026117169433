import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LoadSheet } from "src/app/modules/pilot/domain/dispatch/loadsheet.model";
import { CommonsService } from "src/app/core/services/commons/commons.service";
import { DispatchService } from 'src/app/modules/pilot/services/dispatch.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'dispatch-section',
    templateUrl: './dispatch-section.component.html',
    styleUrls: ['./dispatch-section.component.css', '../dispatch.component.css'],
    standalone: false
})
export class DispatchSectionComponent implements OnInit {

  @Input('type') type: string;
  @Input('title') title: string;
  @Input('redirectTo') redirectTo: string;
  @Input('loadsheet') loadsheet: LoadSheet;
  @Input('alertMessage') alertMessage: string;

  //There are 4 document states: success, error, not available and waiting (initial state)
  @Input() documentState: string = 'waiting';

  @Output() openModal = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<boolean>();

  private isSelected: boolean = false;
  private uncheck: boolean = null;
  public loadsheetSummary: string;
  public loadsheetSummaryClean: string;
  public firstTime: boolean = true;
  public loadsheetSummaryCosmos: string;
  public allowGroupPoc: boolean = false;
  public isTestEnvironment = !environment.production;

  //Sections that have to avoid triggering a redirection or modal open event when they're being selecting
  private sectionsThatNeedCheckingSupport: Array<string>;
  public sectionsThatNeedRefresh: Array<string>;

  public newSections: Array<string>;


  constructor(
    private router: Router,
    private commonsService:CommonsService,
    private dispatchService: DispatchService) {}

  ngOnInit(): void {
    this.sectionsThatNeedCheckingSupport = ['Briefing TM/Crew briefing', 'Route Report', 'Limops Reports','Maintenix Report', 'Ground Equipment Status', 'Directos'];
    this.sectionsThatNeedRefresh = ['Loadsheet', 'Crew Briefing', 'Maintenance condition', 'Directos'];
    this.newSections = ['Weather', 'NOTAM', 'Flight plan', 'Other Documents'];

  }

  emitOpenModalEvent(){
    this.commonsService.registryEvent({ category: 'dispatch_folder', event: `open_${this.title}_modal`});

    if (!this.sectionsThatNeedCheckingSupport.includes(this.title)){
      this.openModal.emit();
      return;
    }
    if(!this.isSelected && !this.uncheck)
      this.openModal.emit();
    this.uncheck = !this.uncheck;
  }

  emitSelectionEvent(){
     this.isSelected = !this.isSelected;
     this.onSelection.emit(this.isSelected);
     this.uncheck = !this.isSelected;
  }

  emitRefreshEvent(){
      this.refresh.emit();
   }

  navigate() {
    this.commonsService.registryEvent({ category: 'dispatch_folder', event: `from_${this.title}_redirecting_to_${this.redirectTo}` });
    if (!this.isSelected && !this.uncheck)
      this.router.navigateByUrl(this.redirectTo);
    this.uncheck = !this.uncheck;
  }
  
}
