<div class="wrapper">

  <mat-card class="mb-3">
    <mat-card-header>
      <div mat-card-avatar class="df-logo"></div>
      <mat-card-title>404</mat-card-title>
      <mat-card-subtitle>{{ 'Page not found'}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      {{ 'The page you are requesting was not found' }}
    </mat-card-content>
    <mat-card-actions style="display: flex; justify-content: center;">
      <ion-button fill="solid" color="primary" size="small" (click)="goHome()">
        Back to home
      </ion-button>
    </mat-card-actions>
  </mat-card>
</div>
