import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { openBrowser } from 'src/app/core/utils/utils';

@Component({
    selector: 'app-aqd-modal',
    templateUrl: './aqd-modal.component.html',
    styleUrls: ['./aqd-modal.component.scss'],
    standalone: false
})
export class AqdModalComponent {

  pdf = "assets/pdf/bannerAQD.pdf";

  constructor(
    public dialogNewsletterView: MatDialogRef<AqdModalComponent>,
  ) { }

  closeClick(): void {
    this.dialogNewsletterView.close(false);
  }

  openLink(url: string): void {
    openBrowser(url);
  }

}
