import { Component, OnInit, HostListener, Input, SimpleChanges, AfterViewInit } from '@angular/core';
import { FlightDetailStatus } from '../../../../domain/flight-detail-status';
import { MatDialog } from '@angular/material/dialog';
import { IndexeddbService } from '../../../../services/indexeddb.service';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { SafetyEventsService } from 'src/app/modules/pilot/services/safety-events.service';
import * as moment from 'moment';

@Component({
    selector: 'app-safety-list',
    templateUrl: './safety-list.component.html',
    styleUrls: ['./safety-list.component.css'],
    standalone: false
})
export class SafetyListComponent implements OnInit, AfterViewInit {

  @Input("events") events: any;
  @Input("showLoading") showLoading: boolean;

  innerWidth: number;
  flightDetailList: Array<FlightDetailStatus> = new Array<FlightDetailStatus>();
  flightSelect: FlightDetailStatus;
  isConnected:boolean;

  constructor(
    private safetyEventsService: SafetyEventsService, 
    public dialog: MatDialog, 
    private _network: NetworkService, 
    protected indexeddbService: IndexeddbService
  ) {
    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(isConnected => {
        this.isConnected = isConnected;
        if(this.isConnected){
          this.ngOnInit();
        }
      });
    }
    valideNetwork();
  }

  ngOnInit() {
    
    if(this.isConnected) {
      this.detectScreenOrientation();
      this.innerWidth = window.innerWidth;
      this.flightSelect = new FlightDetailStatus();
      this.flightSelect.status = true;
    }
  }

  detectScreenOrientation() {
    let self = this;
    //Detect screen rotation
    window.addEventListener("orientationchange", () => {
      if(window.orientation === 90 || window.orientation === -90){
        for (let event of self.flightDetailList){
          if(!event.isVideoPlaying){
            continue;
          }
          this.handleEvent(event);
        }
      }
    }, false);
  }

  handleEvent(event: any) {
    let videoObj = document.getElementById(event.videoId) as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    if (videoObj.requestFullscreen)
        videoObj.requestFullscreen();
    else if (videoObj.webkitRequestFullscreen)
        videoObj.webkitRequestFullscreen();
  }

  ngAfterViewInit(){

    //Video events management
    for (let event of this.flightDetailList){

      if(!event.url || event.hasListener) continue;
      let video: any = document.getElementById(event.videoId);
      video?.addEventListener("ended", () => {
        if(event.seen == 1) return;

        this.safetyEventsService.markVideoEventAsSeen(event.number, event.flightDate, event.phase)
          .subscribe(() => {
            event.seen = 1;
            //Update internal db
            this.indexeddbService.getDataFromTable('safety', 'events').then(safetyEvents => {
              safetyEvents[event.fleet][event.number]['seen'] = 1;
              this.indexeddbService.addToTable('safety', safetyEvents, "events");
            });
          });
      });

      video?.addEventListener("pause",   () => event.isVideoPlaying = false);
      video?.addEventListener("playing", () => event.isVideoPlaying = true);

      event.hasListener = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.events != undefined && this.events != null){
      this.getListFlight();
      this.innerWidth = window.innerWidth;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  getListFlight() {
    let responseEventsProp = Object.keys(this.events);
    let eventsDetailsArray = [];
    for (let prop of responseEventsProp) {
      this.events[prop].number = prop;

      for (let event of this.events[prop].eventList){
        if(event.url != null && event.seen != null){
          this.events[prop].url = event.url;
          this.events[prop].seen = this.events[prop].seen ? this.events[prop].seen : event.seen;
          this.events[prop].phase = event.phase;
          this.events[prop].videoId = `safety-video-${this.events[prop].number}`;
        }
      }

      eventsDetailsArray.push(this.events[prop]);
    }

    this.showLoading = false;
    this.flightDetailList = eventsDetailsArray;

    let data : any = this.flightDetailList;

    data.sort(function (a, b) {
      let bDate = moment(b.flightDate, "DD-MM-YYYY").format('YYYY/MM/DD');
      let aDate = moment(a.flightDate, "DD-MM-YYYY").format('YYYY/MM/DD');
      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    this.allFlightsFalse();
    this.showDetail(this.flightDetailList[0]);
  }

  allFlightsFalse() {
    this.flightDetailList = this.flightDetailList.map((objFlight) => {
      objFlight.status = false;
      return objFlight;
    });
  }

  showDetail(flightSelected) {
    this.flightSelect = flightSelected;
    if (this.flightDetailList.length===0) {
      return;
    } else if(flightSelected.status){
      if(!flightSelected.status){
        this.allFlightsFalse();
        }
      if(flightSelected.status && window.innerWidth < 575){
        this.allFlightsFalse();
      }
    } else {
      this.allFlightsFalse();
      flightSelected.status = true;
    }
  }

}
