import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { PilotConstants } from "src/app/modules/pilot/commons/pilot-constants";
import { FlightId } from "src/app/modules/pilot/domain/flight-id.model";
import * as moment from 'moment';
import { DispatchComponent } from "../dispatch.component";
import Swal from 'sweetalert2';
import { map, startWith } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { CommonsService } from "src/app/core/services/commons/commons.service";
import { UserService } from "src/app/core/services/user/user.service";
import { PreferencesService } from "src/app/core/services/preferences/preferences.service";
import { IUser } from "src/app/modules/pilot/domain/user/user";
import { SecurityService } from "src/app/core/services/security/security.service";

@Component({
    selector: 'other-dispatch-folder',
    templateUrl: './other-dispatch-folder.component.html',
    styleUrls: [
        './other-dispatch-folder.component.css',
        './../../../commons/styles/custom-inputs.scss'
    ],
    standalone: false
})
export class OtherDispatchFolderComponent implements OnInit, OnDestroy {

  @ViewChild(DispatchComponent,{static: false}) dispatchComponent:DispatchComponent;

  @ViewChild('picker') datePicker;
  public ownerList = this.constants.OTHER_DISPATCH_OPERATOR_LIST;
  public departureAirports: Observable<any>;
  public airportsList: Array<string>;

  public formGroup: UntypedFormGroup;
  public isInvalidForm: boolean = false;

  public flightToSearch: FlightId;
  public flightIsDefined: boolean = false;
  public loading: boolean = false;
  user: IUser;
  userSubs: Subscription;
  maxDate: string;

  constructor(
    private constants: PilotConstants,
    private commonsService:CommonsService,
    private _user: UserService,
    private _preferences: PreferencesService,
    private security: SecurityService,
  ){}

  ngOnInit() {
    const currentDate = moment();
    this.maxDate = currentDate.add(1, 'day').utc().format("YYYY-MM-DD");
    this.userSubs =  this._user.getUser().subscribe((user) => { 
      this.user = user;
    });

    this._preferences.getItem('airportsList').then( value => {
      this.airportsList = JSON.parse(value);
      
      this.formGroup = new UntypedFormGroup({
        operator: new UntypedFormControl('', [Validators.required]),
        flightNumber: new UntypedFormControl('', [Validators.pattern('[0-9]{1,4}[a-z]{0,1}')]),
        departure: new UntypedFormControl(''),
        flightDate: new UntypedFormControl(new Date(new Date().setHours(12, 0, 0)), [Validators.required]),
      });
  
      this.loadAutoCompleteFiltersForDeparture();
    });

  }

  ngOnDestroy(): void {
    this.userSubs?.unsubscribe();
  }

  searchDispatchFolder(){

    const operator = this.ownerList.find(op =>op.name ===this.formGroup.get('operator').value).value;
    const flightDate = moment(this.formGroup.get('flightDate').value).toDate();
    let flightNumber = this.formGroup.get('flightNumber').value;
    flightNumber = flightNumber.replace(/^0+/, "");
    const departure = this.formGroup.get('departure').value.toUpperCase();
    this.commonsService.registryEvent({ category: `${this.user.userID} searching ${operator}${flightNumber}`, event: "search_other_dispatch_folder" });

    this.flightToSearch = new FlightId({ flightNumber, operator, flightDate, departureIataCode: departure});
    this.security.setLoading(true);
    if(operator != ""  && flightNumber!="" && departure != ""){
      this.loading = true;
      //FlightIsDefined has to change its value to delete and create a dispatch component (refresh way does not work properly)
      this.flightIsDefined=false;
      setTimeout(() => {
        this.flightIsDefined=true;
        this.isInvalidForm=false;
      }, 1);
      return;
    }
    this.security.setLoading(false);
    this.isInvalidForm=true;
    this.flightIsDefined= false;
  }

  public flightWasFound(resp: boolean) {
    this.loading = false;
    this.flightIsDefined = resp;

    this.security.setLoading(false);

    if (!this.flightIsDefined) {
      Swal.fire({
        icon: 'info',
        title: 'Flight not found',
        text: 'Please try with another flight.'
      });
      return;
    }

    this.scrollToResult();
  }

  filterAirport = (value: string): string[] => this.airportsList?.filter(option => option.toLowerCase().includes(value.toLowerCase()));

  loadAutoCompleteFiltersForDeparture() {
    this.departureAirports = this.formGroup.controls.departure.valueChanges
      .pipe(
        startWith(''),
        map(this.filterAirport)
      );
  }

  clickPicker() {
    this.datePicker.open();
  }

  closeRequiredFieldsAlert(){
    this.isInvalidForm = false;
  }

  scrollToResult() {
    setTimeout(() => {
      document.getElementById("targetDispatchFolder").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 500);
  }
}
