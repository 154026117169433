import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
    standalone: false
})
export class LoadingComponent {
  color = 'primary';
  mode = 'determinate';
  value = 50;

  @Input() text: string = "";


}
