<div *ngIf="activity?.flightLeg != null" class="card-margin">
  <mat-card appearance="outlined">
    <div class="row" style="background-color: #eceff5;border-radius: 6px;">
      <div class="flight-card-left col-xl-9 col-lg-9 col-md-9 col-sm-8 col-8 p-2" (click)="findFlightInformation()">
        <div *ngIf="activity.dutyDesignator == 'PS'" class="pax">
          PAX
        </div>
        <div class="ticket-shadow ticket-shadow-top-left"></div>
        <div class="ticket-shadow ticket-shadow-bottom-left"></div>

        <div class="d-inline-flex center-card-content">
          <div class="flight-datetime">
            <span class="airport">{{ activity.flightLeg.departure.iataCode }}</span>
            <ng-container [ngTemplateOutlet]="dateBySelectedTimezone" [ngTemplateOutletContext]="{dates:activity.flightLeg.departure.dates,cssClass:'airport-datetime mt-1 ml-1', canEndsTomorrow: false}"></ng-container>
          </div>
          <div class="mx-3">
            <img src="/assets/img/home/new-home/flight.svg" alt="">
          </div>
          <div class="flight-datetime">
            <span class="airport">{{ activity.flightLeg.arrival.iataCode }}</span>
            <ng-container [ngTemplateOutlet]="dateBySelectedTimezone" [ngTemplateOutletContext]="{dates:activity.flightLeg.arrival.dates,cssClass:'airport-datetime mt-1 ml-1', canEndsTomorrow: true}"></ng-container>
          </div>
        </div>
        <span class="row col-12 airport-diff center-card-content ml-1">
          DIF. {{activity.flightLeg.departure.iataCode}} & {{activity.flightLeg.arrival.iataCode}}: {{ activity.diff | slice: 0:5 }}
        </span>

        <div *ngIf="!incompleteFlight && activity.dutyDesignator != 'PS' && !activity.isBlocked" class="actions col-12 center-card-content">
          <button class="card-button mr-1 mt-2" (click)="goToFlightReport()">Report</button>
          <button class="card-button mr-1 mt-2" (click)="goToDispatchFolder()">Docs</button>
        </div>

        <span *ngIf="incompleteFlight" style="color:grey">Missing Flight Information</span>

        <span class="ticket-border ticket-border-top"></span>
        <span class="ticket-border ticket-border-bottom"></span>
      </div>
      <div [ngClass]="incompleteFlight || activity.isBlocked? 'incomplete-flight' : ''" class="flight-card-right col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
        <div class="ticket-shadow ticket-shadow-top-right"></div>
        <div class="ticket-shadow ticket-shadow-bottom-right"></div>

        <div class="day-of-week" [ngClass]="{'day-of-week-active': !activity.isBlocked, 'day-of-week-inactive': activity.isBlocked}" *ngIf="!incompleteFlight">
          <span *ngIf="!isFromBrasil">
            {{(checked ? activity.flightLeg.departure.dates.utc : activity.flightLeg.departure.dates.local) | date : 'EEEE' }}
          </span>

          <span *ngIf="isFromBrasil">
            {{(checked ? activity.flightLeg.departure.dates.local : activity.flightLeg.departure.dates.brasilia) | date : 'EEEE' }}
          </span>
        </div>

        <div class="flight-date-and-catering right-card-sm-margin">
          <span *ngIf="!isFromBrasil" class="flight-date">
            {{(checked ? activity.flightLeg.departure.dates.utc : activity.flightLeg.departure.dates.local) | date : 'MMMM dd' }}
          </span>

          <span *ngIf="isFromBrasil" class="flight-date">
            {{(checked ? activity.flightLeg.departure.dates.local : activity.flightLeg.departure.dates.brasilia) | date : 'MMMM dd' }}
          </span>

        </div>

        <span class="flight-number right-card-sm-margin">
          {{ incompleteFlight ?  activity.flightLeg.carrier : activity.flightLeg.airlineCodes.operating}}{{innerWidth > 400 ? '-' : ''}}{{ activity.flightLeg.flightNumber }}
        </span>

        <div class="view-action clickable" (click)="toggleCollapsible()">
          <span>{{innerWidth > 400 ? 'View' : ''}} {{panelIsOpen ? 'Less' : 'More'}}</span>
          <mat-icon class="position-absolute">{{!panelIsOpen ? 'expand_more' : 'expand_less'}}</mat-icon>
        </div>
      </div>
    </div>
  </mat-card>
  <div class="expansion-panel mt-2" #expansionPanel>
    <div class="row">
      <div class="col-5 mt-4 padding-left-expanded-panel">
        <div class="d-flex">
          <img src="/assets/img/rol/gate.svg" alt="">
          <div class="ml-4">
            <p class="extended-panel-info-title gate-bridge-title">GATE</p>
            <p class="extended-panel-info-content gate-bridge-content">{{activity.flightLeg.gate ? activity.flightLeg.gate : '-'}}</p>
          </div>
        </div>
        <div >
          <span class="extended-panel-info-title text-dark font-18">RPT: </span>
          <span class="extended-panel-info-content font-18" *ngIf="incompleteFlight">-</span>
          <ng-container *ngIf="!incompleteFlight" [ngTemplateOutlet]="dateBySelectedTimezone" [ngTemplateOutletContext]="{dates:activity.flightLeg.checkInDates,cssClass:'extended-panel-info-content font-18', canEndsTomorrow: false}"></ng-container>
        </div>
      </div>
      <div class="col-7 d-inline-block mt-4 padding-right-expanded-panel" style="text-align-last: right;">
        <div class="mt-1">
          <span class="extended-panel-info-title text-dark font-18">TAIL: </span><span class="extended-panel-info-content font-18">{{ activity.flightLeg.aircraftRegistration }}</span>
        </div>
        <div class="mt-3">
          <span class="extended-panel-info-title text-dark font-18">FLEET: </span><span class="extended-panel-info-content font-18">{{ activity.flightLeg.fleet }}</span>
        </div>
      </div>
    </div>

    <hr class="hr-expansion-panel">

    <div class="d-flex catering-box">
      <div *ngFor="let food of activity.catering">
        <img src="/assets/img/rol/dinner.svg" alt="">
        <p class="extended-panel-info-title mb-0 font-18">{{food.serviceCode}}</p>
        <p class="extended-panel-info-content">{{food.serviceName}}</p>
      </div>
      <span class="text-center mb-2" *ngIf="!activity.catering">No catering found on this flight</span>
    </div>
  </div>
</div>

<ng-template #dateBySelectedTimezone let-dates="dates" let-cssClass="cssClass" let-canEndsTomorrow="canEndsTomorrow">
  <span class="{{cssClass}}" *ngIf="((!checked && !isFromBrasil) || (checked && isFromBrasil))">{{ dates.local | date : 'HH:mm' }}<i class="time-circle ml-1" *ngIf="canEndsTomorrow && localDateEndsTomorrow">+1</i></span>
  <span class="{{cssClass}}" *ngIf="(checked && !isFromBrasil)">{{ dates.utc | date : 'HH:mm' }}<i class="time-circle ml-1" *ngIf="canEndsTomorrow && utcDateEndsTomorrow">+1</i></span>
  <span class="{{cssClass}}" *ngIf="(!checked && isFromBrasil)">{{ dates.brasilia | date : 'HH:mm' }}<i class="time-circle ml-1" *ngIf="canEndsTomorrow && brasiliaDateEndsTomorrow">+1</i></span>
</ng-template>
