import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.css'],
    standalone: false
})
export class NewsletterComponent {

  numberNoAckSafetyDocuments = 0;
  numberNoAckOperationalDocuments = 0;

  selectedIndex = 0;

  isTestEnvironment: boolean = !environment.production;
}
