import { Component, Input } from "@angular/core";
import { Document } from "../../../domain/profile/document.model";

@Component({
    selector: 'visa-card-list',
    templateUrl: './visa-card-list.component.html',
    styleUrls: ['../profile.component.css'],
    standalone: false
})
export class VisaCardListComponent {

  @Input()
  visas: Array<Document>;

  @Input()
  state: string;

  visaListIsNotEmpty(): boolean{
    return this.visas.length != 0;
  }
}
