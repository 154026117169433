import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectProcessLoop',
    standalone: false
})
export class ObjectProcessLoopPipe implements PipeTransform {

  transform(value: any, args?: any[] | string): Object[] {
    let keyArr: any[] = Object.keys(value),
        dataArr = [],
        keyName = args[0];

    keyArr.forEach((key: any) => {
        value[key][keyName] = key;
        dataArr.push(value[key])
    });

    return dataArr;
  }

}
