import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { PilotConstants } from "src/app/modules/pilot/commons/pilot-constants";
import { map, startWith } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { CommonsService } from "src/app/core/services/commons/commons.service";
import { UserService } from "src/app/core/services/user/user.service";
import { IUser } from "src/app/modules/pilot/domain/user/user";
import { SubcomponentState } from "../../../domain/dispatch/sub-component-state.model";
import { MaintenixReportComponent } from "../maintenix-report/maintenix-report.component";

@Component({
    selector: 'mr2-report',
    templateUrl: './mr2-report.component.html',
    styleUrls: ['./mr2-report.component.css'],
    standalone: false
})
export class MR2ReportComponent implements OnInit {

  @ViewChild(MaintenixReportComponent, { static: false }) maintenixComponent: MaintenixReportComponent;


  public tailList = this.constants.TAIL_NUMBERS;
  public tailSelect: Observable<any>;
  public airportsList: Array<string>;
  public tailToSearch = "";
  public reportFoundInfo: boolean = false;
  public formGroup: UntypedFormGroup;
  public isInvalidForm: boolean = false;

  public searchingState: string = "";
  user: IUser;
  userSubs: Subscription;

  constructor(
    private constants: PilotConstants,
    private commonsService: CommonsService,
    private _user: UserService
  ) { }

  ngOnInit() {
    this.userSubs = this._user.getUser().subscribe((user) => {
      this.user = user;
    });

    this.formGroup = new UntypedFormGroup({
      tail: new UntypedFormControl('', [Validators.pattern('[aA-zZ]{5,6}')]),
    });

    this.loadAutoCompleteFiltersForDeparture();

  }


  searchMR2Report() {
    this.reportFoundInfo = false;
    this.tailToSearch = "";
    this.searchingState = "loading";

    setTimeout(() => {
      this.reportFoundInfo = true;
      this.tailToSearch = this.tailList.find(op => op === this.formGroup.get('tail').value);
    }, 10)

    this.commonsService.registryEvent({ category: `${this.user.userID} searching ${this.tailToSearch}`, event: "mr2_report_search"});
  }


  filterAirport = (value: string): string[] => this.tailList?.filter(option => option.toLowerCase().includes(value.toLowerCase()));

  loadAutoCompleteFiltersForDeparture() {
    this.tailSelect = this.formGroup.controls.tail.valueChanges
      .pipe(
        startWith(''),
        map(this.filterAirport)
      );
  }

  closeRequiredFieldsAlert() {
    this.isInvalidForm = false;
  }

  public maintenixReady(main: SubcomponentState) {
    if (main.state == "success") {
      this.searchingState = main.state;
    } else {
      this.searchingState = "error";
    }
  }
}
