import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatDate',
    standalone: false
})
export class FormatDatePipe implements PipeTransform {

  transform(date: string): any {
    if(date != null && date != ""){
      let splitedDate = date.split("-");
      return splitedDate[2] +"-"+ splitedDate[1] +"-"+splitedDate[0]
    }
  }

}
