import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, finalize, firstValueFrom } from 'rxjs';
import { confirmAlert } from 'src/app/core/utils/utils';
import { FlightLogInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-info.model';
import { FlightLog } from 'src/app/modules/pilot/domain/flight-log/flight-log.model';
import { IFlightsByDays } from 'src/app/modules/pilot/domain/flight-log/order-flights.interface';
import { FlightLogService } from 'src/app/modules/pilot/services/flight-log.service';
import { IndexeddbService } from 'src/app/modules/pilot/services/indexeddb.service';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import * as moment from 'moment';
import { PilotConstants } from '../../../commons/pilot-constants';
import { CommonsService } from 'src/app/core/services/commons/commons.service';


@Component({
    selector: 'past-flights',
    templateUrl: './past-flights.component.html',
    styleUrls: ['./past-flights.component.scss', '../../rol/briefing-tm/briefing-tm.component.css'],
    standalone: false
})
export class PastFlightsComponent implements OnInit, OnDestroy {

    flightsByDays: IFlightsByDays = {};
    objectKeys = Object.keys;
    loadingFlights: boolean = false;

    @Output() addFlightsEvent = new EventEmitter<FlightLogInfo[]>();
    isConnected:boolean;
    private subs: Subscription;
    flightLogSuccess: boolean = false;
    flightsToSend: number = 0;
    flightlogsToSend: number = 0;
    refreshSubs: Subscription;
    pastFlightsLastUpdate: string;

    constructor(
        private flightLogService: FlightLogService,
        private indexeddb: IndexeddbService,
        public _network: NetworkService,
        private securityService: SecurityService,
        private _ui: UiService,
        private _preferences: PreferencesService,
        private _constants: PilotConstants,
        private commonsService: CommonsService
    ) {}

    ngOnInit(): void {
        this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
            if (state.refresh)  {
              this.getPastFlights(true);
            };
        });
        this.init();
    }

    ngOnDestroy(): void {
        this.flightLogService.setState(false);
        this.subs?.unsubscribe();
        this.refreshSubs?.unsubscribe();
    }

    async init() {
        this.commonsService.registryEvent({ category: 'flight_log', event: "flight_log_past_flights_view"});
        this._preferences.getItem('last-past-flights-update').then( value => this.pastFlightsLastUpdate = value || "...");
        this.isConnected = await this._network.getStatusNetwork();
        this._network.getState().subscribe(isConnected => this.isConnected = isConnected);
        this.getPastFlights();
        this.subs = this.flightLogService.getState().subscribe(
            reDraw => {
                reDraw && this.getPastFlights();
            }
        );
    }

    async getPastFlights(refresh: boolean = false) {
        this.loadingFlights = true;
        let today = moment().utc(true).format('YYYY-MM-DD');
        let lastUpdatePastFlights = await this._preferences.getItem(this._constants.KEY_LAST_UPDATE_PAST_FLIGHTS);
        if (this.isConnected && (lastUpdatePastFlights != today || refresh)) {
            this.flightLogService.getPastFlights()
            .pipe(finalize(() => this.loadingFlights = false)).subscribe({
                next: (resp: FlightLogInfo[]) => {
                    this.indexeddb.addToTable('rol', resp, "pastFlights");
                    this._preferences.setItem(this._constants.KEY_LAST_UPDATE_PAST_FLIGHTS, today);
                    this.orderFlightsByDays(resp, refresh);
                    let updatedAt: string =  moment().utc().format('MMM Do YY HH:mm')
                    this._preferences.setItem('last-past-flights-update', updatedAt);
                    this.pastFlightsLastUpdate = updatedAt;
                }
            });
        } else {
            this.indexeddb.getDataFromTable('rol', 'pastFlights').then((pastFlights: FlightLogInfo[]) => {
                if (pastFlights && pastFlights?.length > 0) {
                    this.orderFlightsByDays(pastFlights, refresh);
                } else {
                    this.loadingFlights = false;
                    refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
                }
            });
        }
    }

    orderFlightsByDays(pastFlights: FlightLogInfo[], refresh: boolean) {
        this.indexeddb.getDataFromTable('rol', 'flightlogs').then(async (flightlogs: FlightLog[]) => {
            this.flightlogsToSend = 0;
            this.flightsToSend = 0;
            if (flightlogs && flightlogs?.length > 0) {
                this.flightlogsToSend = flightlogs.length;
                if (!this.isConnected) {
                    pastFlights.forEach( pF => {
                        flightlogs.forEach( fl => {
                            let findFlightsCreatedID = fl.flights.find( flight => {
                                return flight.flightNumber == pF.flightNumber && flight.flightDate == pF.flightDate && flight.aircraftRegistrationNumber == pF.aircraftRegistrationNumber;
                            });
                            if (findFlightsCreatedID) {
                                pF.flightCreated = true;
                                pF.pendingSend = true;
                            }
                        });
                    });
                } else {
                    // con conexion se envian flogs
                    await this.sendFlightlogs(flightlogs);
                    pastFlights = await this.indexeddb.getDataFromTable('rol', 'pastFlights');
                }
                flightlogs.forEach( fl => {
                    this.flightsToSend += fl.flights.length;
                });
            }
            
            let groupByDay = pastFlights.reduce((r, a) => {
                r[a.flightDate] = [...r[a.flightDate] || [], a];
                return r;
            }, {});

            for (const key in groupByDay) {
                // buscar si algun vuelo del dia no ha sido creado
                let some = groupByDay[key].some( f => !f.flightCreated);
                this.flightsByDays[key] = { flights: groupByDay[key], complete: !some }; 
            }
                
            this.loadingFlights = false;
            refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
        });
    }

    sendFlightlogs(flightlogs: FlightLog[]) {
        this.securityService.setLoading(true);
        return new Promise<void>((resolve, reject) => {
            let request = [];
            for (const fl of flightlogs) {
                request.push(firstValueFrom(this.flightLogService.createFlightLog(fl)));
            }
            Promise.all(request)
                .then(async (res: any[]) => {
                    this.indexeddb.deleteElementFromDatabase('rol', 'flightlogs');
                    this.flightLogSuccess = true;
                    let selectedFlights = [];
                    flightlogs.forEach( fl => {
                        selectedFlights = [...selectedFlights, ...fl.flights]
                    });
                    await this.flightLogService.updateFlightStatus(selectedFlights);
                    this.securityService.setLoading(false);
                    this.flightLogService.setState(true);
                    resolve();
                }).catch(
                    err => {
                        this.securityService.setLoading(false);
                        resolve();
                        console.error("Error createFlightLog - no connection ", err);
                    }
                );
        });
    }

    selectFlight(flight: FlightLogInfo) {
        flight.selected = !flight.selected;
    }

    redirectToCreation(){
        let selectedFlights = [];
        for (const key in this.flightsByDays) {
            let flightsByDate = this.flightsByDays[key].flights.filter(f => f?.selected);
            if (flightsByDate.length > 0) {
                selectedFlights = selectedFlights.concat(flightsByDate);
            }
        }
        if (selectedFlights.length > 0) {
            this.addFlightsEvent.emit(selectedFlights);
        } else {
            confirmAlert({
                icon: 'info',
                title: 'No flights selected',
                text: 'You have to select at least one flight.',
                showCancelButton: false,
                confirmButtonText: "OK"
            }, true );
        }
    }

}