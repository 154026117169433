import { Component, OnDestroy, OnInit } from '@angular/core';
import { QRCodeErrorCorrectionLevel } from "qrcode";
import { Subscription, finalize } from 'rxjs';
import { QRCode } from 'src/app/modules/pilot/domain/login/qrCode';
import { CommonsService } from 'src/app/core/services/commons/commons.service';
import { ProfileService } from 'src/app/modules/pilot/services/profile.service';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { UserService } from 'src/app/core/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-login-qr',
    templateUrl: './login-qr.component.html',
    styleUrls: ['./login-qr.component.scss'],
    standalone: false
})
export class LoginQrComponent implements OnInit, OnDestroy {

  errorCorrectionLevel:QRCodeErrorCorrectionLevel = 'H';
  widthQR = 280;
  QRData: QRCode;
  loadingQR: boolean = false;
  refreshSubs: Subscription;
  refresh = false;
  
  constructor(
    public _user: UserService,
    private _profile: ProfileService,
    private _common: CommonsService,
    private _ui: UiService,
  ) { }
  
  ngOnInit(): void {
    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh)  {
        this.refresh = true;
        this.init();
      };
    });
    this.init();
  }

  ngOnDestroy(): void {
    this.refreshSubs?.unsubscribe();
  }
  
  init() {
    this._common.registryEvent({ event: "view_generate_qr_section", category: "qr_login" }); 
    this.saveSession();
  }
  
  saveSession() {
    this.loadingQR = true;
    let user = this._user.getUserValue;
    if (!user) return;
    this._common.saveSession(user).subscribe({
      next: (resp) => {
        console.log("success");
        this.getQrCode();
      },
      error: err => {
        console.error("Error saveSession", err);
        Swal.fire({
          title: 'Error',
          text: 'Oooops! There was a problem generating the QR code. Please try again',
          icon: 'error'
        });
        this.loadingQR = false;
        this._ui.setStateRefresh({ refresh: false, finishEvent: true });
      }
    });
  }

  getQrCode() {
    this._profile.getQrCode()
      .pipe(finalize(() => {
        this.loadingQR = false;
      })) 
      .subscribe({
        next: (resp: QRCode) => {
          this.QRData = resp;
          this._common.registryEvent({ event: "generate_qr", category: "qr_login" }); 
          if (this.refresh) {
            this.refresh = false;
            this._ui.setStateRefresh({ refresh: false, finishEvent: true });
          }
        },
        error: (err) => {
          console.error("Error getQrCode ", err);
          this._ui.setStateRefresh({ refresh: false, finishEvent: true });
        },
      });
  }

}
