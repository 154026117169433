import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'mapReader',
    standalone: false
})
export class MapReaderPipe implements PipeTransform {

  transform(map: any) : any {
    if(!map)
      return [];
    
    let arr = [];
    map.forEach(value => arr.push(value));
    return arr;
  }
}