import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/pilot/components/home/home.component';
import { SafetyComponent } from './modules/pilot/components/safety/safety.component';
import { ReportsComponent } from './modules/pilot/components/reports/reports.component';
import { LoginComponent } from './modules/pilot/components/login/login.component';
import { AuthGuardService } from './core/guards/auth/auth-guard.service';
import { ProfileComponent } from './modules/pilot/components/profile/profile.component';
import { NewsletterComponent } from './modules/pilot/components/newsletter/newsletter.component';
import { RolComponent } from './modules/pilot/components/rol/rol.component';
import { FaqComponent } from './modules/pilot/components/faq/faq.component';
import { OtherFlightsComponent } from './modules/pilot/components/other-flights/other-flights.component';
import { DispatchComponent } from './modules/pilot/components/dispatch/dispatch.component';
import { BtmReportComponent } from './modules/pilot/components/dispatch/btm-report/btm-report.component';
import { OtherDispatchFolderComponent } from './modules/pilot/components/dispatch/other-dispatch-folder/other-dispatch-folder.component';
import { MR2ReportComponent } from './modules/pilot/components/dispatch/mr2-report/mr2-report.component';
import { FlightLogComponent } from './modules/pilot/components/flight-log/flight-log.component';
import { CalculatorComponent } from './modules/pilot/components/calculator/calculator.component';
import { OnBoardingComponent } from './standalone-modules/on-boarding/on-boarding.component';
import { LoginGuard } from './core/guards/auth/login.guard';
import { LoginQrComponent } from './modules/pilot/components/login-qr/login-qr.component';
import { NotFoundComponent } from './standalone-modules/not-found/not-found.component';

const routes: Routes = [

    // lazy loading
    {   
        path: "tools",
        loadChildren: () => import('./modules/tools/tools.module').then(m => m.ToolsModule),
        data: { routePermissions: ['COMMUNICATIONS', 'DISPATCH', 'FAQ', 'SAFETY', 'FUEL', 'MEDAL_GOALS', 'NEWS'] },
        canActivate: [AuthGuardService]
    },
    { 
        path: 'fuel',
        loadChildren: () => import('./modules/pilot/components/fuel/fuel.module').then(m => m.FuelModule),
        data: { routePermissions: ['PILOT'] },
        canActivate: [AuthGuardService]
    },
    { 
        path: 'roster', 
        loadChildren: () => import('./modules/pilot/components/rol/rol.module').then(m => m.RolModule),
        data: { routePermissions: ['PILOT'] },
        canActivate: [AuthGuardService]
    },
    // lazy loading
    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'qr', component: LoginQrComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'safety', component: SafetyComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'reports/:flightNumber/:flightDate/:operator/:fleet/:tail/:departure/:arrival', component: ReportsComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'newsletter', component: NewsletterComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'roster/:section', component: RolComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'faq', component: FaqComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'other-flights', component: OtherFlightsComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'btm-report', component: BtmReportComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'dispatch-folder', component: DispatchComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'find-dispatch-folder', component: OtherDispatchFolderComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'flight-logs', component: FlightLogComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'mr2-report', component: MR2ReportComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'calculator', component: CalculatorComponent },
    { path: 'on-boarding', component: OnBoardingComponent, canActivate: [AuthGuardService], data: { routePermissions: ['PILOT'] } },
    { path: 'not-found', component: NotFoundComponent},
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: "not-found", pathMatch: "full" },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
