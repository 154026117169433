import { Component, Input } from '@angular/core';
import { HelpModalComponent } from '../../modals/help-modal/help-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-crew-briefing',
    templateUrl: './crew-briefing.component.html',
    styleUrls: ['./crew-briefing.component.css'],
    standalone: false
})
export class CrewBriefingComponent {
  @Input('loadingCrewBriefing') loadingCrewBriefing : any;
  @Input('dataCrewBriefing') dataCrewBriefing : any;
  @Input('isFromBrasil') isFromBrasil : any;
  dialogRef: any;
  
  constructor(public dialog: MatDialog) { }

  showInfo(){
    let data = {};
    data = { 
      title: "CREW BRIEFING",
      body: '<h6>Dispatch Briefing LT</h6>'+
      'Time for the flight crew to present and effectuate their briefing. <br><br>'+
      '<h6>Briefing FC/CC</h6>'+
      'Time in witch the Operational briefing should be finished between the flight crew and the cabin crew.<br><br>'+
      '<h6>Time at the Gate</h6>'+
      'Time for the Crew to pass the gate to board the plane.<br><br>'+
      '<h6>Pass. Boarding LT</h6>'+
      'Time at which initiate autonomous boarding.'
    }
      
    this.dialogRef = this.dialog.open(HelpModalComponent, {
      width:"500px",
      data: data,
      disableClose: true
    });
  }
}
