import { Component, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LoadSheet } from 'src/app/modules/pilot/domain/dispatch/loadsheet.model';
import { FlightId } from 'src/app/modules/pilot/domain/flight-id.model';
import { ILoadSheetByFlight } from 'src/app/modules/pilot/domain/loadsheet/loadsheet-by-flight.interface';
import { INotifications } from 'src/app/modules/pilot/domain/notifications/notifications';
import { DispatchService } from 'src/app/modules/pilot/services/dispatch.service';
import { environment } from 'src/environments/environment';
import { IndexeddbService } from '../../services/indexeddb.service';
import { Activity } from '../../domain/roster/activity.model';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/core/services/security/security.service';
import * as moment from 'moment';
import { RolService } from '../../services/rol.service';
import { RosterCommon } from '../../commons/roster-common';
import { confirmAlert } from 'src/app/core/utils/utils';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: false
})
export class NotificationsComponent implements OnChanges {

  @Input() notifications: INotifications[] = [];
  @Input() isConnected: boolean = true;

  @Output() close: EventEmitter<any> = new EventEmitter();

  numberNotificationsLS = 0;
  numberNotificationsOFP = 0;
  numberNotificationsCOMM = 0;
  numberNotificationsOpEvents = 0;

  loadsheet: LoadSheet;
  allowACK: boolean = false;
  allowLMC: boolean = false;

  isTestEnvironment: boolean = !environment.production;

  constructor(
    private _dispatchService: DispatchService,
    private indexeddbService: IndexeddbService,
    private ngZone: NgZone,
    private router: Router,
    private securityService: SecurityService,
    private rolService: RolService,
    private rosterCommon: RosterCommon,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.numberNotificationsLS = this.countNotificationsUnViewed("loadsheet");
    this.numberNotificationsOFP = this.countNotificationsUnViewed("OFPMessage");
    this.numberNotificationsCOMM = this.countNotificationsUnViewed("communications");
    this.numberNotificationsOpEvents = this.countNotificationsUnViewed("OperationalEvent");
  }

  countNotificationsUnViewed(type: string) {
    let notificationsunView = this.notifications.filter(n => !n.view && n.typeNotification == type);
    return notificationsunView?.length || 0;
  }

  closeNotifications(): void {
    this.close.emit();
  }

  async onClick(notification: INotifications) {

    let { idLoadSheet, flight, typeNotification, description } = notification;

    if (typeNotification == "communications" || typeNotification == "OperationalEvent") {
      this.closeNotifications();
      return;
    }

    if (flight && typeNotification == "loadsheet") {
      this.openLoadSheet(flight, idLoadSheet);
      return;
    }

    if (typeNotification == "OFPMessage") {
      this.openDispatchFolder(description);
      return;
    }
  }

  openLoadSheet(flight: FlightId, idLoadSheet: number) {
    const flightDate = flight?.flightDate;
    const fecha = new Date(flightDate);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const monthFormatted = month < 10 ? `0${month}` : month;
    const dayFormatted = Number(flight.flightDayUtc) < 10 ? `0${flight.flightDayUtc}` : flight.flightDayUtc;
    let flightRequest: ILoadSheetByFlight = {
      flightDate,
      flightNumber: flight?.flightNumber,
      operator: flight.operator,
      departureIataCode: flight.departureIataCode,
      arrivalIataCode: flight.arrivalIataCode,
      flightDateUtc: `${year}-${monthFormatted}-${dayFormatted}`,
      tail: flight.tail,
      dateOfFlight: `${year}-${monthFormatted}-${dayFormatted}`
    }
    this.securityService.setLoading(true);
    this._dispatchService.getLoadSheet(flightRequest)
      .subscribe({
        next: (listLoadsheets: LoadSheet[]) => {
          this.showLoadSheet(listLoadsheets, idLoadSheet);
          this.securityService.setLoading(false);
        },
        error: error => {
          this.securityService.setLoading(false);
          console.error("Error at getLoadsheet: ", error);
          this.loadsheet = null;
          this.allowACK = false;
          this.allowLMC = false;
        }
      });
  }

  showLoadSheet(listLoadsheets: LoadSheet[], idLoadSheet: number) {
    if (listLoadsheets && listLoadsheets.length > 0) {
      let index = listLoadsheets.findIndex(ls => ls.id == idLoadSheet);
      if (index != -1) {
        this.loadsheet = listLoadsheets[index];
        this.loadsheet.version = index + 1;
        if (idLoadSheet == this.loadsheet.id) {
          this.allowACK = true;
          this.allowLMC = true;
        }
       this._dispatchService.showLoadSheetModal({
          loadSheet: this.loadsheet,
          allowACK: this.allowACK,
          allowLMC: this.allowLMC,
          isConnected: this.isConnected,
          listLoadSheet:null,
          activateCosmosCompare: false,
          versionLS: null
        });
        return;
      }
      this.allowACK = false;
      this.allowLMC = false;
    }
  }

  async openDispatchFolder(texto: string) {
    this.securityService.setLoading(true);
    const regex = /^([A-Za-z0-9]{2})(\d{1,4})\s([A-Za-z]+)-([A-Za-z]+)\s(\d{2}-\d{2}-\d{4})$/;
    const match = texto.match(regex);
    let flightInformation: Activity;
    if (match) {
      const operatorValue = match[1];
      const flightNumberValue = Number(match[2]);
      const departureValue = match[3];
      const arrivalValue = match[4];
      const flightDateValue = match[5];

      let activities: Activity[] = await this.indexeddbService.getDataFromTable('rol', 'activities');

      if (!activities || activities?.length == 0) {
        activities = await this.getRoster();
      }

      flightInformation = activities.find(activity => {
        let { flightLeg } = activity;

        return flightLeg?.flightNumber == flightNumberValue &&
          flightLeg?.arrival.iataCode == arrivalValue &&
          flightLeg?.departure.iataCode == departureValue &&
          flightLeg?.flightDate == moment(flightDateValue, 'DD-MM-YYYY').format('YYYY-MM-DD') &&
          flightLeg?.airlineCodes.operating == operatorValue
      });

      if (flightInformation) {
        this.goToDispatchFolder(flightInformation);
        return;
      }
    } 

    confirmAlert({
      icon: "info",
      title: 'Opps!',
      text: 'It was not possible to open the dispatch folder for the flight. Please try again.',
      confirmButtonText: 'Accept',
      showCancelButton: false
    }, true);
    this.securityService.setLoading(false);
  }

  getRoster() {
    return new Promise<Activity[]>((resolve, reject) => {
      this.rolService.getRoster("LA", this.rosterCommon.getSearchDates('YYYY-MM-DD'))
          .subscribe({
            next: async resp => {
              console.log("resp: ", resp);
              resolve(resp)
            },
            error :err => {
              console.log(err);
              resolve([]);
          }});
    });
  }

  goToDispatchFolder(flightInformation: Activity) {
    let { flightLeg } = flightInformation;
    const queryParams: any = {
      flightNumber: flightLeg.flightNumber,
      tail: flightLeg.aircraftRegistration,
      operator: flightLeg.carrier,
      departureIataCode: flightLeg.departure.iataCode,
      aircraftSubtype: flightLeg.fleet,
      arrivalIataCode: flightLeg.arrival.iataCode,
      carrierOperating: flightLeg.airlineCodes.operating,
      utcDate: flightLeg.departure.dates.utc, // LEG date UTC
      localDate: flightLeg.departure.dates.local,  // LEG date Local
      dateOfFlight: flightLeg.flightDate // add date of flight
    };
    // console.log(queryParams);
    this.ngZone.run(() => {
      this.securityService.setLoading(false);
      this.closeNotifications();
      this.router.navigate(["dispatch-folder"], { queryParams });
    });
  }
}
