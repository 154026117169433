import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef, AfterContentChecked, NgZone } from '@angular/core';
import { CommonsService } from '../../../../core/services/commons/commons.service';
import { ReportService } from '../../services/report.service';
import { ReportRequest } from '../../domain/reportRequest';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, finalize } from 'rxjs';
import { IndexeddbService } from '../../services/indexeddb.service';
import { DateToBackPipe } from '../../commons/pipes/date-to-back.pipe';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { RosterFcard } from '../rol/roster-fcard/roster-fcard.component';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { FlightInformationComponent } from '../rol/flight-information/flight-information.component';
import { RolService } from 'src/app/modules/pilot/services/rol.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { RosterCommon } from 'src/app/modules/pilot/commons/roster-common';
import { environment } from 'src/environments/environment';
import { DispatchService } from 'src/app/modules/pilot/services/dispatch.service';
import { LoadSheet } from 'src/app/modules/pilot/domain/dispatch/loadsheet.model';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { Device } from '@capacitor/device';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { Activity } from 'src/app/modules/pilot/domain/roster/activity.model';
import { ILoadSheetByFlight } from 'src/app/modules/pilot/domain/loadsheet/loadsheet-by-flight.interface';
import { cleanOldFiles } from 'src/app/core/utils/utils';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [
        DateToBackPipe
    ],
    standalone: false
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentChecked {

  nextFlightFlag = false;
  isConnected: boolean;
  airportsList: string[] = [];
  reasonList: any;

  // Flight report
  pendingReport: boolean;
  reportSuccess: boolean = false;
  reportRequest: ReportRequest = new ReportRequest();
  statusSendReport: boolean = false;
  sendingReport: boolean = false;

  // Graphics fuel
  dashboardExtraFuel: any = {percentLoad: null, averageLoad: null, percentUsed: null, averageUsed: null };
  dashboardSA: any = null;
  dashboardOET: any = {TAXI_IN: null, TAXI_OUT: null};
  dashboardCCI: any = {percentFleet: null, percentPilot: null};
  dashboardOTP: any;
  dashboardOTPHeader: any;
  loadingExtraFuel: boolean = false;
  loadingApu: boolean = false;
  loadingStabAlt: boolean = false;
  loadingOET: boolean = false;
  loadingCCI: boolean = false;
  loadingOTP: boolean;

  // 
  activity: Activity;
  months: string = "";
  
  fleetSelected: string;
  fleetCategory = '';
  subscription: Subscription;
  month: string;

  // profile pilot
  contractCodeOperator: string;

  isTestEnvironment: boolean = !environment.production;
  environment: string = environment.envName;

  // loadsheet 
  loadsheetWaiting: boolean = false;
  flightlogsToSend: number = 0;
  loadsheetExist: boolean = false;
  loadsheets: Array<any> = new Array<any>();
  loadsheet: LoadSheet;
  allowACK:boolean =false;
  allowLMC:boolean =false;
  showFlightInformation: boolean = false;

  // user
  user: IUser;
  userSubs: Subscription;
  refreshSubs: Subscription;

  hideSection: boolean = this._constants.hideSectionsStores;

  @ViewChild(FlightInformationComponent) flightInfoComponent: FlightInformationComponent;

  @Output("locationNav") locationNav = new EventEmitter();
  @ViewChild(RosterFcard,{static: false}) rosterFcard: RosterFcard;

  constructor(
    private indexeddb: IndexeddbService,
    public snackBar: MatSnackBar,
    private commonsService: CommonsService,
    private reportService: ReportService,
    private router: Router,
    public dialog: MatDialog,
    public _network: NetworkService,
    private changeDetector: ChangeDetectorRef,
    private rolService: RolService,
    private rosterCommon: RosterCommon,
    private _user: UserService,
    private _preferences: PreferencesService,
    private _dispatchService: DispatchService,
    private _constants: PilotConstants,
    private ngZone: NgZone, 
    private _ui: UiService,
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh)  {
        this.userSubs?.unsubscribe();
        this.init(true);
      };
    });
    this.init();
  }

  ngOnDestroy(){
    this.subscription?.unsubscribe();
    this.userSubs?.unsubscribe();
    this.refreshSubs?.unsubscribe();
  }

  async init(refresh = false) {
    this.isConnected = await this._network.getStatusNetwork();
    this.valideNetwork();
    this.locationNav.emit("home");
    let months = await this._preferences.getItem("months");
    this.months = (months == null ? "3" : months);
    this.month = moment().format("MMMM");

    this.userSubs = this._user.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        this.contractCodeOperator = this.user?.profile?.contractCode?? this.user.profile.operator;
        this.fleetCategory =  (this.user?.profile?.fleet === 'A320F') ? 'narrow' : 'widebody';
        this._preferences.getItem('nextflightFlag').then( value => this.nextFlightFlag = JSON.parse(value));
        this.loadNextFlight();
      }
    });

    this.verifyRequestFlightReport();

    this.sendLastMinuteChangeID();
    setTimeout(() => {
      refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
    }, 2000);

    this.deleteOldFiles();
  }

  async valideNetwork () {

    this._network.getState().subscribe(isConnected => this.isConnected = isConnected);
    let airportsList = JSON.parse(await this._preferences.getItem("airportsList"));
    let reasonList = JSON.parse(await this._preferences.getItem("reasonList"));
    if (!airportsList) {
      this.loadAirports();
    } else {
      this.airportsList = airportsList;
    }

    if (!reasonList) {
      this.loadReasonList();
    } else {
      this.reasonList = reasonList;
    }
  }

  loadNextFlight() {
    // Se prioriza llamado al servicio getRoster cuando hay internet para tener los datos actualizados
    if (this.isConnected) {
      this.rolService.getRoster(this.contractCodeOperator, this.rosterCommon.getSearchDates('YYYY-MM-DD', true))
      .subscribe(roster => this.assignRoster(roster));
    } else {
      this.indexeddb.getDataFromTable('rol', 'activities').then(response => {
        if (response != undefined && response.length > 0) {
          this._preferences.setItem('nextflightFlag', JSON.stringify(true));
          this.assignRoster(response);
        }
      });
    }
  }

  getLoadsheetInIndexedDB() {
    this.indexeddb.getDataFromTable("rol", "lastLoadsheet").then(async (loadsheet: LoadSheet) => {
      if (loadsheet) {
        this.loadsheet = loadsheet;
        this.loadsheetExist = true;
      };
    });
  }

  getLoadsheet(showLoadsheet = false){
    
    if (!this.activity || this.activity.dutyDesignator == "PS" ) return;

    if (!this.isConnected) {
      this.getLoadsheetInIndexedDB();
      return;
    }

    this.loadsheetWaiting = true;
    let activity: Activity = this.activity;
    
    let flightRequest: ILoadSheetByFlight = {
      flightDate:  activity.flightLeg.departure.dates.local,
      flightNumber: activity.flightLeg.flightNumber,
      operator: activity.flightLeg.airlineCodes.operating,
      departureIataCode: activity.flightLeg.departure.iataCode,
      arrivalIataCode: activity.flightLeg.arrival.iataCode,
      flightDateUtc: activity.flightLeg.departure.dates.utc,
      tail: activity.flightLeg.aircraftRegistration,
      dateOfFlight: activity.flightLeg.flightDate
    }

    this._dispatchService.getLoadSheet(flightRequest)
    .pipe(finalize(() => { this.ngZone.run(() => this.loadsheetWaiting = false); }))
    .subscribe({
      next: listLoadsheets => {
        this.ngZone.run(() => {
          if (listLoadsheets && listLoadsheets.length > 0){
            let length = listLoadsheets.length;
            this.loadsheet = listLoadsheets[length - 1];
            this.loadsheet.version = length;
            this.loadsheetExist = true;
            showLoadsheet && this.openLoadSheetModal();
          } else {
            this.loadsheetExist = false;
          }
        });
      },
      error: error => {
        console.error("Error at getLoadsheet: ", error)
        this.loadsheetExist = false;
      }
    });
  }

  public openLoadSheetModal() {
    if (this.loadsheetExist) {
      this.isConnected && this.commonsService.registryEvent({ category: 'home', event: "open_loadsheet_modal"});;
      this.showLoadsheet();
    }
  }

  async showLoadsheet() {
    this.allowACK = true;
    this.allowLMC = true;
    this.loadsheet.fleet = this.activity.flightLeg.fleet;
    let { role } = await this._dispatchService.showLoadSheetModal({
      loadSheet: this.loadsheet,
      allowACK: this.allowACK,
      allowLMC: this.allowLMC,
      isConnected: this.isConnected,
      listLoadSheet: null,
      activateCosmosCompare: false,
      versionLS: null
    });
    if (role === 'close') {
      this.getLoadsheet();
    }
  }

  async assignRoster(roster: any){
    let { flight } = this.rolService.getNextFlight(roster);

    this.activity = flight;
    if(this.activity){
      const route = {
        departure: this.activity.flightLeg.departure.iataCode,
        arrival: this.activity.flightLeg.arrival.iataCode
      }
      this.indexeddb.addToTable('profile', route, "route");
      this.activity.flightLeg.gate = await this.rolService.getGate(this.activity);
      this.nextFlightFlag = true;
      this.setNextFlight(this.activity);
    } else {
      this.nextFlightFlag = false;
    }
    this.nextFlightFlag = JSON.parse(await this._preferences.getItem('nextflightFlag'));
  }

  setNextFlight(activity: any) {
    this._preferences.getItem("nextFlight").then( value => {

      const nextFlight: any = JSON.parse(value);

      const arrival: string = activity?.flightLeg.arrival.iataCode;
      const departure: string = activity?.flightLeg.departure.iataCode;
      const flightNumber: number = activity?.flightLeg.flightNumber;
      const flightDate: string = activity?.flightLeg.departure.dates.utc;
      const operator: string = activity?.flightLeg.operator;
      const carrier: string = activity?.flightLeg.carrier;

      if(nextFlight && (nextFlight.departure != departure || nextFlight.flightNumber != flightNumber || nextFlight.flightDate != flightDate || nextFlight.operator != operator)){
        this._preferences.setItem('nextFlightCrewIsLoaded', JSON.stringify(false));
      }

      this.getLoadsheet();

      this._preferences.setItem('nextFlight', JSON.stringify({arrival, departure, flightNumber, flightDate, carrier, operator}));

    });
  }

  loadAirports() {
    if (this.isConnected) {
      this.commonsService.getAirports().subscribe({
        next: (resp:any) => {
          this.airportsList = resp;
          this._preferences.setItem('airportsList', JSON.stringify(this.airportsList));
        },
        error: err => console.error("Error getAirports: ", err)
      });
    }
  }

  loadReasonList() {
    if (this.isConnected) {
      this.commonsService.getFlightReportReasonList().subscribe({
        next: (resp:any) => {
          this.reasonList = resp;
          this._preferences.setItem('reasonList', JSON.stringify(this.reasonList));
        },
        error: err => console.error("Error getAirports: ", err)
      });
    }
  }

  verifyRequestFlightReport() {
    this.indexeddb.getDataFromTable('report', 'requestFlightReport').then(value => {
      this.pendingReport = value != undefined;
      this.reportRequest = value;
      if (this.isConnected && this.reportRequest && !this.sendingReport) {
        this.sendingReport = true;
        this.reportService.postFlightReport(this.reportRequest).subscribe({
          next: resp => {
            this.flightReportSent();
          },
          error: err => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Ooops! There was a problem sending your Report.'
            });
            this.sendingReport = false;
          }
        });
      }
    });
  }

  flightReportSent() {
    Swal.fire({ icon: 'success', title: 'success', text: 'Your Report has been sent' });
    this.indexeddb.deleteElementFromDatabase('report', 'FlightReportStatus');
    this.indexeddb.deleteElementFromDatabase('report', 'requestFlightReport');
    this.reportSuccess = true;
    this.pendingReport = false;
    this.statusSendReport = true;
    this.sendingReport = false;
    this.commonsService.registryEvent({ category: 'flight_report', event: "send_report_offline"});
    this.reportService._pendingReport.next(false);
  }

  goToDispatchFolder(){
    const { flightLeg } = this.rosterFcard.activity;
    const queryParams: any = {
      flightNumber : flightLeg.flightNumber,
      tail : flightLeg.aircraftRegistration,
      operator : flightLeg.carrier,
      departureIataCode : flightLeg.departure.iataCode,
      aircraftSubtype : flightLeg.fleet,
      arrivalIataCode : flightLeg.arrival.iataCode,
      carrierOperating : flightLeg.airlineCodes.operating,
      utcDate : flightLeg.departure.dates.utc, // LEG date UTC
      localDate : flightLeg.departure.dates.local,  // LEG date Local
      dateOfFlight: flightLeg.flightDate // add date of flight
    };
    this.commonsService.registryEvent({ category: 'home', event: 'go_to_dispatch_folder'});
    this.router.navigate(["dispatch-folder"],{ queryParams});
  }

  openFlightInformation(){
    this.commonsService.registryEvent({ category: 'home', event: 'open_flight_information'});
    this.showFlightInformation = true;
  }

  closeFlightInformation(){
    this.commonsService.registryEvent({ category: 'home', event: 'close_flight_information'});
    this.showFlightInformation = false;
  }

  sendLastMinuteChangeID() {
    this.indexeddb.getDataFromTable('rol', 'lastMinuteChange').then(async (data: any) => {
      if (data && this.isConnected && this.isTestEnvironment) {
        this._dispatchService.lastMinuteChange(data).subscribe({
          next: (res) => {
            this.indexeddb.deleteElementFromDatabase('rol', 'lastMinuteChange');
            Swal.fire({
              icon: 'success',
              title: 'Updated',
              text: 'Your last minute change has been updated successfully'
            });
          },
          error: () => Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was a problem saving your last minute change'
          })
        });
      }
    });
  }

  async deleteOldFiles() {
    let info = await Device.getInfo();
    let { platform } = info;
    if (platform != "web") cleanOldFiles(2);
  }
}
