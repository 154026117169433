import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { DataChart } from 'src/app/modules/pilot/domain/data-chart';

@Component({
    selector: 'app-safety-graphic',
    templateUrl: './safety-graphic.component.html',
    standalone: false
})
export class GraphicComponent implements OnChanges {


  @Input("title") title:string;
  @Input("data") data:any;
  @Input("showLoading") showLoading:boolean;

  lineChartType:ChartType = 'line';
  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [],
  };
  lineChartColors:Array<any> = this.constants.LINE_CHART_COLORS;
  lineChartOptions: ChartOptions<'line'> = this.constants.LINE_CHART_OPTIONS;
  lineChartLegend = true;
  theresData: boolean = true;

  constructor(
    private constants: PilotConstants
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      this.lineChartData = { datasets: [] };
      this.setData();      
    }
  }

  setData() {
    this.checkData();

    //set labels on x axys
    this.lineChartData.labels = this.data.periodList;

    //fleet line
    let lineFleet:DataChart = new DataChart();
    lineFleet.data = this.data.quantityList;
    lineFleet.label = "Fleet";
    lineFleet = { ...lineFleet,...this.lineChartColors[1] };
    
    //pilot line
    let linePilot:DataChart = new DataChart();
    linePilot.data = this.data.quantityPilotList;
    linePilot.label = "Yours";
    linePilot = { ...linePilot, ...this.lineChartColors[0] };

    //push to graphic
    this.lineChartData.datasets.push(linePilot,lineFleet);
    
  }

  checkData(){
    this.theresData = this.data.quantityList.length != 0;
  }

}
