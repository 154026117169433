import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { BriefingTmService } from 'src/app/modules/pilot/services/briefing-tm.service';

import * as moment from 'moment';
import { Duty } from 'src/app/modules/pilot/domain/roster/duty.model';
import { IndexeddbService } from 'src/app/modules/pilot/services/indexeddb.service';
import { RosterCommon } from 'src/app/modules/pilot/commons/roster-common';
import { SimpleDialog } from 'src/app/core/services/dialog/simple-dialog.service';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { RolComponent } from '../rol.component';
import { Activity } from 'src/app/modules/pilot/domain/roster/activity.model';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { Device } from '@capacitor/device';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { confirmAlert } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { App } from '@capacitor/app';

@Component({
    selector: 'briefing-tm',
    templateUrl: './briefing-tm.component.html',
    styleUrls: ['./briefing-tm.component.css', '../rol.component.css'],
    standalone: false
})
export class BriefingTmComponent implements OnInit {

  public briefingTmData: Array<Duty>;
  public screenWidth: number;

  @Input('isFromBrasil') isFromBrasil: any;
  @Input('checked') checked: any;

  @Output() hasData = new EventEmitter<boolean>();
  @Output() updating = new EventEmitter<boolean>();
  @Output() backFromFCard = new EventEmitter();

  today = moment().local();
  isTestEnvironment: boolean = !environment.production;
  
  constructor(
    private briefingTmService: BriefingTmService, 
    public constants: PilotConstants,
    private indexeddbService: IndexeddbService,
    protected _network: NetworkService,
    protected simpleDialog: SimpleDialog,
    @Inject(RolComponent) public rolComponent: RolComponent,
    private rosterCommon: RosterCommon,
    private _preferences: PreferencesService,
    private _security: SecurityService
  ) {}

  async ngOnInit() {
    this.screenWidth = window.innerWidth;
    await this.rosterCommon.monitorNetWorkState();  
    this.rosterCommon.isConnected ? this.updateBriefing() : this.loadBriefingData();
  }

  private loadBriefingData() {
    this.indexeddbService.getDataFromTable('rol', 'briefing')
      .then(response => {
        this.briefingTmData = response;
        this.hasData.emit(this.briefingTmData?.length != 0);
        this.getInfoApp();
      });
  }

  private async getInfoApp() {
    let info = await Device.getInfo();
    if (info.platform != "web") {
      let app = await App.getInfo();
      if (app.id == this.constants.IDS_IOS.ID_APP_STORE_ENTERPRISE || this.isTestEnvironment) {
        this.valideAutomaticLogOut();
      }
    }
  }

  private async valideAutomaticLogOut() {

    let briefingToday = this.briefingTmData?.find(b => {
      let dateArrival = moment(b.plannedStart.local);
      return dateArrival.isSame(this.today, 'day');
    });

    if (briefingToday) {

      let value = await this._preferences.getItem("nextLastFlightByTail");
      let nextLastFlightByTail: Activity = value? JSON.parse(value): this.getNextLastFlight(briefingToday);

      if (nextLastFlightByTail) {
        this._preferences.setItem("nextLastFlightByTail", nextLastFlightByTail);
        let finalDate = moment(nextLastFlightByTail.flightLeg.arrival.dates.local);
        let differenceInMinutes = finalDate.diff(this.today, 'minutes');
        this.handleLogOut(differenceInMinutes)
      }
      
    }
  }

  private getNextLastFlight(briefingToday: Duty) {
    let groups = briefingToday.items.reduce((r, a) => {
      r[a.flightLeg.aircraftRegistration] = [...r[a.flightLeg.aircraftRegistration] || [], a];
      return r;
    }, {});

    let nextLastFlightByTail: Activity;
    for (const key in groups) {
      let flight: Activity = groups[key][groups[key].length - 1];
      let fechaObjetivo = moment(flight.flightLeg.arrival.dates.local);
      let differenceInMinutes = fechaObjetivo.diff(this.today, 'minutes');
      

      if (differenceInMinutes > 0) {
        nextLastFlightByTail = flight;
        break;
      }
    }
    return nextLastFlightByTail;
  }

  async handleLogOut(differenceInMinutes: number) {
    if (differenceInMinutes <= -15) {
      await confirmAlert({
        title: 'The session has expired.',
        text: 'Please log in again.',
        confirmButtonText: 'Accept',
        showCancelButton: false,
        allowOutsideClick: false
      });
      this._security.logOut();
    }
  }

  public updateBriefing(){
    
    this.updating.emit(true);
    const searchDates: any = this.rosterCommon.getSearchDates("DD-MM-YYYY");

    this.briefingTmService.getBriefingTM(`${searchDates.startDate}`, `${searchDates.endDate}`).subscribe(resp => {

      resp?.forEach(duty => {
        const utcStartDt = moment(duty.plannedStart.utc);
        const utcEndDt = moment(duty.plannedEnd.utc);

        duty.dutyUtcDateEndsTomorrow = !utcStartDt.isSame(utcEndDt, 'day');
        duty.dutyLocalDateEndsTomorrow = !moment(duty.plannedStart.local).isSame(moment(duty.plannedEnd.local), 'day');
        duty.dutyBrasiliaDateEndsTomorrow = !moment(duty.plannedStart.brasilia).isSame(moment(duty.plannedEnd.brasilia), 'day');
        duty.dutyTime = moment.utc(utcEndDt.diff(utcStartDt)).format("HH:mm");
      });

      if(!resp || resp.length == 0){
        this.updating.emit(false);
        return;
      }

      // this.rosterCommon.addExtraDataToRoster(resp[0].items, false).then((roster: Activity[]) => {
        // resp[0].items = roster;
        // this.indexeddbService.addToTable('rol', resp, "briefing");
        this.briefingTmData = resp;
      // });

      this.indexeddbService.addToTable('rol', resp, "briefing");

      this._preferences.setItem('last-briefing-update', moment().utc().format('DD/MMM/YY HH:mm'));
      
      this.loadBriefingData();
      this.updating.emit(false);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  backToRoster(){
    this.backFromFCard.emit();
  }

}