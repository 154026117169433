import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PilotConstants } from '../commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewsletterListRequest } from '../domain/newsletter-list-request';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    private httpClient: HttpClient,
    private Constants: PilotConstants
  ) { }

  listNewsletter(newsletterListRequest: NewsletterListRequest, section: string): Observable<any> {
    let params = new HttpParams()
    .set('operator', newsletterListRequest.operator)
    .set('fleet', newsletterListRequest.fleet)
    .set('startDate', newsletterListRequest.startDate)
    .set('endDate', newsletterListRequest.endDate)
    .set('section', section);
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.PILOT_LIST_NEWSLETTER}`;
    return this.httpClient.get(URL, { params });
  }

  setAcknowledge(requestAcknowledge): Observable<any> {
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.PILOT_ACKNOWLEDGE}`;
    return this.httpClient.post(URL, requestAcknowledge);
  }

  getTypes(section: string): Observable<any> {
    let params = new HttpParams()
    .set('section', section);
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.PILOT_COMMUNICATION_TYPES}`;
    return this.httpClient.get(URL, { params });
  }

  checkCommunicationAck(idCommunication: number) {
    let params = new HttpParams()
    .set('idCommunication', idCommunication)
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.PILOT_ACKNOWLEDGE_BY_BP}`;
    return this.httpClient.get<boolean>(URL, { params });
  }
}
